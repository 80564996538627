import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import moment from 'moment';
import { Spin } from "antd";
export default class Tracking extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      requestData: [],
      loading: false
    }
  }


  async componentDidMount() {
    document.title = 'Tracking | DBC'
    this.setState({loading: true})
    const RequesTransaction = {
      page_no: 0,
    }
    const datareq = await this.props.getRequesTransaction(RequesTransaction);
    if (datareq.value.status === 200) {
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ requestData: datareq.value.data , loading: false});
    }else if (datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
    this.setState({loading: false})
  }


  render() {
    const {requestData}= this.state
    return (
      <div>
        <div className="pageWithMenu">
        <Spin spinning={this.state.loading} delay={500}>
          <div className="profilePages">
            <h1 className="profileHeading">Tracking</h1>
            <div className="profile-container">
                <div className="trackingCards">
                {(requestData.length>0)? 
                requestData.map((item, i) => {
                    return(
                      <div key={i} className="trackingCard">
                    <div className="tc-header">
                      <h4>{item.service_name}</h4>
                      <p>Service ID: {item.service_id}</p>
                    </div>
                    <div className="tc-content">
                      <div className="tcc-item">
                        <p>Date & Time of Visit</p>
                        <h5>{moment(item.create_on).format("D MMMM")}&nbsp;-&nbsp; {moment(item.create_on).format("hh:mm A")}</h5>
                      </div>
                      <div className="tcc-item amt">
                        <p>Amount Paid</p>
                        <h5>{item.total_payment} AED</h5>
                      </div>
                      <div className="tccLink">
                        <Link to={`/track/${item.service_req_id}`}><img src="./images/icons/arrow-right.png"/></Link>
                      </div>
                    </div>
                  </div>
                    )
                }): <h5>Data Not Found</h5>}
                </div>
              </div>
          </div>
          </Spin>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><Link to="/"><img src="./images/icons/menu-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-active-3.png" alt="menu"/></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu"/></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-4.png" alt="menu"/></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
