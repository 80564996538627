import { connect } from 'react-redux'
import HomeComponent from './component'
import { getAllServices} from '../../store/service/duck'
import { getAllUnreadMessages } from '../../store/request/duck'

const HomeContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getAllServices,
    getAllUnreadMessages
   
  }
  // Map actions to dispatch and props
)(HomeComponent)

export default HomeContainer
