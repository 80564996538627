import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default class Privacy extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      requestData: {}
    }
  }


  async componentDidMount() {
    document.title = 'Privacy | DBC'

      const Data = await this.props.getContent({
        page_type: 'privacy'
      });
      if (Data.value.status === 200) {
        // console.log(Data.value.data)
        this.setState({requestData: Data.value.data})
      }
    
  }


  render() {
    const {requestData}= this.state
    return (
      <div>
        <div className="pageWithMenu">
          <div className="profilePages">
            <h1 className="profileHeading">{requestData.heading}</h1>
            <div className="profile-container">
            { ReactHtmlParser(requestData.description) }
            </div>
          </div>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><Link to="/"><img src="./images/icons/menu-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-active-3.png" alt="menu"/></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu"/></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-4.png" alt="menu"/></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
