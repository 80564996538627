import React, { PureComponent } from "react";
import "./styles.scss";
import { Spin, message, Upload } from 'antd';
import { Link } from "react-router-dom";
import { 
  SendMessageIcon,
  AttachFile
} from '../../components/icons';
export default class Chatboard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageText: '',
      service_req_id: '',
      userID: '',
      admin_id: '',
      previousHistory: [],
      allHistory: [],
      selectedFile: null,
      per_page: 10,
      Hostname: 'https://dbcapi.webvilleedemo.xyz/uploads/',
      allTrackingData: [],
      loading: false
    }
  }

  componentDidMount() {
    document.title = 'Chatboard | DBC'
    // console.log(this.props, 'this props')
    const uID = window.localStorage.getItem('userID')
    const adminid = window.localStorage.getItem('admin_id')
    const id = this.props.match.params.id;
    this.setState({ service_req_id: id, userID: uID, admin_id: adminid });
    // console.log(id, 'hjghjg')
    // this.getTrackingData(id);
    this.getAllTrackingData();

    this.props.socket.on("send_message_ack", (data) => {
      // console.log("receive send message :- ", data);
      if (data.status === 200) {
        // console.log("jhjh", data.data);
        if (this.state.allHistory.map(({ _id }) => _id).indexOf(data.data._id) === -1) {
          this.setState({ allHistory: [...this.state.allHistory, data.data] })
        }
        this.setState({ messageText: '' });
        // console.log(this.state.allHistory, 'msgggg');
        // this.getAllHistory();
        this.scrollToBottom();
      } else {
        message.error("something went wrong please try again")
      }
    });

    this.props.socket.on("recieve_message", (data) => {
      // console.log("receive send message :- ", data);
      if (data.status === 200) {
        // console.log("jhjh", data.data);
        this.setState({ allHistory: [...this.state.allHistory, data.data] });
        const messageDt = {
          recieverId: data.data.recieverId,
          requestId: data.data.requestId,
        }
        // console.log(messageDt, '8989898989')
        this.props.socket.emit('read_messages_ack', messageDt);
        // console.log(this.state.allHistory, 'msgggg');
        // this.getAllHistory();
        this.scrollToBottom();
      } else {
        message.error("something went wrong please try again")
      }
    });

    if (document.getElementById("chatList").scrollTop > 350 || document.getElementById("chatList").scrollTop > 350) {
      // console.log(document.getElementById("chatList").scrollTop, 'scroll');
    }

    // console.log(document.getElementById("chatList").scrollTop, 'scroll00000');

    this.getAllHistory();
    // this.scrollToBottom();
  }

  async getAllTrackingData() {
    const trackingData = {
      service_req_id: this.props.match.params.id
    }
    const datareq = await this.props.getTrackingData(trackingData);
    if (datareq.value.status === 200) {
      const allData = datareq.value.data[0]
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ allTrackingData: allData });
    } else if (datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
  }


  getAllHistory = async () => {
    this.setState({ loading: true });
    const { per_page } = this.state;
    const MessagesData = {
      requestId: this.props.match.params.id,
      user_id: window.localStorage.getItem('userID'),
      page_no: 0,
      perPage: per_page
    }

    const datamsg = await this.props.getAllChatHistory(MessagesData);
    if (datamsg.value.status === 200) {
      this.setState({ allHistory: datamsg.value.data })
      // this.setState({ allHistory: previousHistory});
      if (per_page === 10) {
        this.scrollToBottom()
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }


  onSendClicked = () => {
    if (!this.state.messageText) {
      return;
    }
    const {messageText, service_req_id } = this.state;
    // console.log(this.props.
    //   socket, 'asasdasdad', admin_id, 'admin_id')

    const messageData = {
      recieverId: window.localStorage.getItem('admin_id'),
      senderId: window.localStorage.getItem('userID'),
      message: messageText,
      requestId: service_req_id
    }
    this.props.socket.emit('send_msg', messageData);
  }

  onMessageInputChange(e) {
    this.setState({ messageText: e.target.value });
  }

  onMessageKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSendClicked();
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  onSendFile = async (file) => {
    this.setState({loading:true});
    const { service_req_id, } = this.state;
    if (file.file.status === "done") {
      // console.log(file);
      var extension = file.file.name.substring(file.file.name.lastIndexOf('.') + 1);
      let fileType;
      (extension === "pdf") ? fileType = 3 : fileType = 2;
      if (file.file.response.status===200) {
        // console.log(file.file.response.data.filepath, 'helll')
        const messageData = {
          recieverId: window.localStorage.getItem('admin_id'),
          senderId: window.localStorage.getItem('userID'),
          requestId: service_req_id,
          file_data: file.file.response.data.filepath,
          file_type: fileType, // 2 image 3 pdf
          // ext: extension,
        }
        // console.log(messageData, 'yyyyyyyyy');
        this.props.socket.emit('send_msg_file', messageData);
        this.setState({loading:false});
      }else if (file.file.response.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        message.error("Please try again")
        this.setState({ loading: false });
      }
      return;
    }else if (file.file.status === 'error') {
      message.error(`${file.file.name} file upload failed.`);
      this.setState({ loading: false });
    }
  }

  scrollToBottom = () => {
    const chat = document.getElementById("chatList");
    // chat.scrollTop = chat.scrollHeight;
    chat && chat.scrollTo(0, chat.scrollHeight);
  };

  handleScrollToElement = () => {
    var elmnt = document.getElementById("chatList");
    var y = elmnt.scrollTop;
    var i;
    if (y === 0) {
      i = this.state.per_page + 10;
      // console.log(i, 'yutyutyu')
      this.setState({ per_page: i }, () => {
        this.getAllHistory()
      })
      // console.log(y, '0');
    }

  }

  render() {
    const { messageText, allHistory, userID, Hostname, allTrackingData, loading } = this.state
    const token = window.localStorage.getItem('token')
    return (
      <div>
        <div className="pageWithMenu">
          <div className="chatPages">
            <Spin spinning={loading} delay={500}>
              <h1 className="profileHeading"><Link to={`/track/${this.props.match.params.id}`}><img alt="back" src="../images/icons/left.png" /> {allTrackingData.service_name}</Link></h1>
              <div className="transList">
                <div className="chatWindow">
                  <ul className="chat" id="chatList" onScroll={this.handleScrollToElement}>
                    {allHistory.length > 0 && allHistory.map(message =>
                      <div key={message._id} >
                        {userID === message.senderId ? (
                          <li className="self">
                            <div className="msg">
                              <p>{message.sender_name}</p>
                              {(message.type === 1) ? <div className="message"> {message.message}</div>
                                : (message.type === 2) ? <div className="message"><a  rel="noopener noreferrer" href={Hostname + message.message} target='_blank'><img style={{ width: 50, height: 50 }} alt="example" src={Hostname + message.message} /></a> </div> :
                                  (message.type === 3) ? <div className="message"> <a  rel="noopener noreferrer" href={Hostname + message.message} target='_blank'>Pdf File - Download</a></div> : ''
                              }
                              <p>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(message.sendOn)}</p>
                            </div>
                          </li>
                        ) : (
                            <li className="other">
                              <div className="msg">
                                <p>{message.sender_name}</p>
                                {(message.type === 1) ? <div className="message"> {message.message}</div>
                                  : (message.type === 2) ? <div className="message"><a  rel="noopener noreferrer" href={Hostname + message.message} target='_blank'><img style={{ width: 50, height: 50 }} alt="example" src={Hostname + message.message} /></a> </div> :
                                    (message.type === 3) ? <div className="message"> <a  rel="noopener noreferrer" href={Hostname + message.message} target='_blank'>Pdf File - Download</a></div> : ''
                                }
                                <p>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(message.sendOn)}</p>
                              </div>
                            </li>
                          )}
                      </div>
                    )}</ul>

                  <div className="formdata">
                    <div className="sendMessagewithupload">
                      <input type="text" onKeyDown={this.onMessageKeyPress}
                        value={messageText} onChange={this.onMessageInputChange.bind(this)} className="input" placeholder="Type a message here..." autofocus />
                      <div className="customUpload">
                      
                      <Upload accept="application/pdf, image/*"
                                  name="file"
                                  showUploadList={false}
                                  headers={{ token: `${token}`}}
                                  action="https://dbcapi.webvilleedemo.xyz/web/document_upload"
                                  // onChange={this.handleChange}
                                  onChange={(file) => this.onSendFile(file)}
                                ><div className="customUploadContent"><AttachFile/></div>
                                </Upload>
                      </div>
                    </div>
                    <button type="button" onClick={this.onSendClicked}><SendMessageIcon/></button>
                  </div>

                </div>
              </div>
            </Spin>
          </div>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><a href="/"><img src="../images/icons/menu-1.png" alt="menu" /></a></li>
                <li><a href="/tracking"><img src="../images/icons/menu-active-3.png" alt="menu" /></a></li>
                <li className="menuPlus"><a href="/add-request/new_request"><img src="../images/icons/plus.png" alt="menu" /></a></li>
                <li><a href="/transactions"><img src="../images/icons/menu-2.png" alt="menu" /></a></li>
                <li><a href="/menu"><img src="../images/icons/menu-4.png" alt="menu" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
