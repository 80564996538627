import React, { PureComponent } from 'react'

import "./styles.scss";
import { Link } from "react-router-dom";

export default class Splash extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
  
    }
 
  }

  componentDidMount() {
    document.title = 'Splash | DBC';
    
    setInterval(()=>{
      this.props.history.push(`/StepFirst`)
    }, 1500);
  }

  render() {

    return (
      <div className="splashScreen">
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="./images/logo.png"/>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

