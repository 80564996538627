import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Spin, message } from "antd";
import 'antd/dist/antd.css';
import moment from 'moment';

export default class Track extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      visible: true,
      confirmLoading: false,
      allTrackingData: [],
      loading: false,
      trackingUrl: {},
      copySuccess: false,
      alltrackDetails: {}
    }
  }

  async componentDidMount() {
    document.title = 'Track | DBC'
    this.setState({loading: true})
     const id =  this.props.match.params.id;
    //  console.log(id,'hjghjg')
    // this.getTrackingData(id);
    const trackingData = {
      service_req_id: id
    }
   

    const datareq = await this.props.getTrackingData(trackingData);
    if (datareq.value.status === 200) {
      const allData = datareq.value.data[0]
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ allTrackingData: datareq.value.data[0] , loading: false});
      fetch("https://api.staging.quiqup.com/oauth/token", {
          "method": "POST",
          "headers": {
            "Content-Type": "application/json",
            "accept": "application/json"
          },
          "body": JSON.stringify({
            "grant_type": "client_credentials",
            "client_id": "7d267325aee321c82db3dd3fbbe3fd5949ef22975c8f22b8147bcff4d93ab581",
            "client_secret": "695233d31d995e21d9f4838162dffeba63990648c0001d7a5ecc3f157a81c6e8"
          })
        })
          .then(response => response.json())
          .then(response => {
            // console.log(response, 'response')
            if (response.access_token) {
              // console.log(response, scheduled_date);
              fetch(`https://api.staging.quiqup.com/partner/jobs/${allData.trackingIdPickup}`, {
                "method": "GET",
                  "headers": {
                    "Authorization": `Bearer ${response.access_token}`,
                    "Content-Type": "application/json",
                    "accept": "application/json"
                  },
                })
                .then(res => res.json())
                .then(async (res) => {
                  // console.log(res.orders[0], 'ljhj');
                  this.setState({alltrackDetails: res, trackingUrl: res.orders[0]})
                })
                .catch(error => {
                  message.error(error);
                });
            } else {
              message.error(response.error + ' please recheck');
              this.setState({ loading: false });
            }
          })
          .catch(err => {
            message.error(err);
          });
    }else if (datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
  }


  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    // console.log(el)
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  render() {
    const { allTrackingData, trackingUrl, alltrackDetails } = this.state
    // console.log(trackingUrl.pickup);
    var trackurl = (trackingUrl.pickup)? trackingUrl.pickup.tracking_url: "#"
    // allTrackingData.request_status =3
    // console.log(allTrackingData.request_status, allTrackingData.update_on)
    return (
      <div>
      <Spin spinning={this.state.loading} delay={500}>
        <div className="profilePages track">
          <div className="profile-withbottomButton">
            <h1 className="profileHeading"><Link to="/tracking"><img src="../images/icons/left.png" /> Track</Link></h1>
            <div className="profile-container">
              <div className="trackingCards">
                <div className="trackingCard">
                  <div className="tc-header">
                    <h4>{allTrackingData.service_name}</h4>
                    <p>Service ID: {allTrackingData.service_id}</p>
                  </div>
                  <div className="tc-content">
                    <div className="tcc-item">
                      <p>Date & Time of Visit</p>
                      <h5> <h5>{moment(allTrackingData.create_on).format("D MMMM")}&nbsp;-&nbsp; {moment(allTrackingData.create_on).format("hh:mm A")}</h5></h5>
                    </div>
                    <div className="tcc-item amt">
                      <p>Amount Paid</p>
                      <h5>{allTrackingData.total_premium} AED</h5>
                    </div>
                    <div className="tccLink">
                    <p>Tracking URL</p>
                    {/* <textarea 
            ref={(textarea) => this.textArea = textarea}
            value={trackurl}
          />
                    <button onClick={() => this.copyCodeToClipboard()}>
                    Copy tracking URL
          </button>
          {
            this.state.copySuccess ?
            <div style={{"color": "green"}}>
              Success!
            </div> : null
          } */}
          {(alltrackDetails.state === "cancelled") ? "TRACKING ORDER IS CANCELLED" :
                   <a target="_blank" href={trackurl}>URL</a>}
                     
                      </div>
                  </div>
                </div>
              </div>
              <div className="trackingProgress">
              {allTrackingData.request_status > 2 ? '' :
                <div className="trackPItem">
                  <div class="d-flex justify-content-between">
                    <p>Document Delivery</p>
                    <p>Expected date 25.06</p>
                  </div>
                </div>}
                {allTrackingData.request_status > 1 ? '' : <div className="trackPItem">
                  <h4>Processing</h4>
                  <div class="d-flex justify-content-between">
                    {/* <p>Dubai , UAE</p> */}
                    <p>{moment(allTrackingData.update_on).format("D MMMM")}&nbsp;-&nbsp; {moment(allTrackingData.update_on).format("hh:mm A")}</p>
                  </div>
                </div>}
                {allTrackingData.request_status > 0 ? '' : <div className="trackPItem">
                  <h4>Arrived at destination for {allTrackingData.request_type}</h4>
                  <div class="d-flex justify-content-between">
                    {/* <p>Dubai , UAE</p> */}
                    <p>{moment(allTrackingData.update_on).format("D MMMM")}&nbsp;-&nbsp; {moment(allTrackingData.update_on).format("hh:mm A")}</p>
                  </div>
                </div>}

                <div className="trackCompleted">
                  {allTrackingData.request_status === 3 ?
                    <div className="trackPItem">
                      <div class="d-flex justify-content-between">
                        <p>Document Delivery</p>
                        <p>Expected date 25.06</p>
                      </div>
                    </div>
                    : ''}
                  {allTrackingData.request_status > 1 ?
                    <div className="trackPItem">
                      <h4>Processing</h4>
                      <div class="d-flex justify-content-between">
                        {/* <p>Dubai , UAE</p> */}
                        <p>{moment(allTrackingData.update_on).format("D MMMM")}&nbsp;-&nbsp; {moment(allTrackingData.update_on).format("hh:mm A")}</p>
                      </div>
                    </div>
                    : ''}
                  {allTrackingData.request_status > 0 ?
                    <div className="trackPItem">
                    <h4>Arrived at destination for {allTrackingData.request_type}</h4>
                      <div class="d-flex justify-content-between">
                        {/* <p>Dubai , UAE</p> */}
                        <p>{moment(allTrackingData.update_on).format("D MMMM")}&nbsp;-&nbsp; {moment(allTrackingData.update_on).format("hh:mm A")}</p>
                      </div>
                    </div>
                    : ''}
                  <div className="trackPItem">
                    <h4>Request Created</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profileBottomButton">
            <Link  to={`/chatboard/${allTrackingData._id}`}  className="btn btn-primary">CHAT WITH US</Link>
            <Link  to={`/receipt/${allTrackingData._id}`} className="btn btn-link">See Receipt</Link>
          </div>
        </div>
      </Spin>
      </div>

    );
  }
}
