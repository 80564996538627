import { connect } from 'react-redux'
import ForgotAffiliateComponent from './component'
import {  forgotPassword, userClearPhase, codeClearPhase } from '../../store/user/duck'

const ForgotAffiliateContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    forgotPasswordPhase: state.user.forgotPasswordPhase
  }),
  {
    forgotPassword,
    userClearPhase,
    codeClearPhase
  }
  // Map actions to dispatch and props
)(ForgotAffiliateComponent)

export default ForgotAffiliateContainer
