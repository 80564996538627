import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Input, Spin, Button, message } from 'antd';

export default class FinalFeesBreakdown extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      service_databyId: [],
      err: {},
      message: "",
      selectedItem: [],
      loading: false,
      pickup_charge: 0,
      promo_code: '',
      promoData: {},
      request_type: '',
      totalPay: '',
      service_name: '',
      order_detail: [],
      promoData_val:0,
      subtotal: 0,
      promocode_value:0
    };
  }

  async componentDidMount() {
    const service_id = window.localStorage.getItem('service_id')
    if (!service_id) {
      this.props.history.push('/home');
    }
    var pickupCharges;
    const datacharge = await this.props.userSetting();
    if (datacharge.value.status === 200) {
      pickupCharges = parseInt(datacharge.value.data.meta_value);
    }

    this.setState({ loading: true });
    const service_type = window.localStorage.getItem('service_type')
    let dataobj = {
      service_id: service_id
    }
    const datas = await this.props.getServicesbyID(dataobj)
    if (datas.value.status === 200) {
      this.setState({ service_name: datas.value.data.service_name })
      var feeData = ''
      if (service_type === "is_new") {
        feeData = JSON.parse(datas.value.data.feestructure_new);
      } else if (service_type === "is_renewal") {
        feeData = JSON.parse(datas.value.data.feestructure_renewal);
      } else if (service_type === "is_modify") {
        feeData = JSON.parse(datas.value.data.feestructure_modify);
      } else if (service_type === "is_cancellation") {
        feeData = JSON.parse(datas.value.data.feestructure_cancellation);
      }
     

      const userRole = window.localStorage.getItem('role');
      let service_data = ''
      if (userRole == 2) {
        service_data = feeData.filter(it => new RegExp(true, "i").test(it.affiliate));
      } else {
        service_data = feeData.filter(it => new RegExp(true, "i").test(it.base));
      }

      this.setState({ service_databyId: service_data, loading: false });
    }
    const request_id = window.localStorage.getItem('request_id')
    if (request_id) {
      const reqobj = {
        service_req_id: request_id
      }
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {
        if (datar.value.data.request_type === "pickup") {
          this.setState({ pickup_charge: pickupCharges, totalPay: datar.value.data.total_premium })
        }
        if(datar.value.data.promocode){
          this.setState({promo_code:datar.value.data.promocode, promocode_value:datar.value.data.promocode_value})
        }
      }
    }
  }

  handlePromo = (e) => {
    this.setState({ promo_code: e.target.value })
  }

  promoSumbit = async (e) => {
    this.setState({ loading: true });
    const { promo_code, service_databyId } = this.state
    if (promo_code) {
      const promoData = {
        promo_code: promo_code,
      }
      const datasuccess = await this.props.getPromoCode(promoData);
      if (datasuccess.value.status === 200) {
        message.success(datasuccess.value.msg);
        this.setState({ promoData: datasuccess.value.data });
        let totalprcie;
        let promoalldata= datasuccess.value.data
        totalprcie = service_databyId.reduce((totalCalories, meal) => totalCalories + parseInt(meal.price, 10), 0);
        if (promoalldata.promo_type === 2) {
          let promoAmount = totalprcie * promoalldata.amount / 100
          this.setState({ promoData_val: promoAmount });
          totalprcie = totalprcie - promoAmount
        }else if (promoalldata.promo_type === 1) {
          this.setState({ promoData_val: promoalldata.amount });
          totalprcie = totalprcie - promoalldata.amount
        }
        this.setState({ loading: false });
      }else if (datasuccess.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        this.setState({ promo_code: '', promoData_val: 0 , promoData: {}, loading: false})
        message.error(datasuccess.value.msg);
      }
    }
  }

  handleSubmit = async (total_premium) => {
    this.setState({ loading: true });
    const {service_databyId, promo_code, pickup_charge, promoData_val} =this.state
    const request_id = window.localStorage.getItem('request_id')
    const userRole = window.localStorage.getItem('role');
    if (userRole == 4) {
      return false;
    }

    const reqData = {
      service_req_id: request_id,
      total_premium: total_premium,
      promocode: promo_code,
      pickup_charge: pickup_charge,
      order_detail: service_databyId,
      promocode_value: promoData_val
    }
    // console.log(reqData);
    const datasuccess = await this.props.UpdateRequests(reqData);
    if (datasuccess.value.status === 200) {
      this.setState({ loading: false });
      this.props.history.push('/payment-method');
      // message.success(datasuccess.value.msg);
    }else if (datasuccess.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    } else {
      message.error(datasuccess.value.msg);
      this.setState({ loading: false });
    }
  }

  render() {

    const { service_databyId, pickup_charge, promoData, totalPay, service_name , promocode_value } = this.state
    // const userRole = window.localStorage.getItem('role');
    // console.log(promoData, 'promodata');
    // console.log(service_databyId)
    var totalp;
    let totalprcie
    totalprcie = service_databyId.reduce((totalCalories, meal) => totalCalories + parseInt(meal.price, 10), 0);
    if (promoData.promo_type === 2) {
      console.log(promoData.amount, 2222222)
      var promoAmount = totalprcie * promoData.amount / 100
      totalprcie = totalprcie - promoAmount
      //  console.log(totalp, '10%');
    } else if (promoData.promo_type === 1) {
      totalprcie = totalprcie - promoData.amount
      // console.log(totalp, '10');
    }
    (promocode_value) ? totalprcie = totalprcie - promocode_value:  totalprcie = totalprcie ;
    (pickup_charge) ? totalp = Math.round(totalprcie) + pickup_charge : totalp = Math.round(totalprcie)
    // console.log(totalp, 'kjkh')
    // (totalPay) ? totalp = totalPay : totalp 
    return (
      <div><Spin spinning={this.state.loading} delay={500}>
        <div className="addRequestInner">
          <div className="addReqContainer">
            <h5 className="heading_5">Processing Fees:</h5>
            <div className="tableTopData">
              <h6>{service_name}</h6>
              <p><span>Address</span> :  HSR Layout Dubai City</p>
            </div>
            <table className="table table-dashed">
              <tbody>
                {service_databyId.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th>{item.label}</th>
                      <td>{item.price} AED</td>
                    </tr>
                  )
                })}
                {(pickup_charge) ?
                  <tr>
                    <th>Subtotal</th>
                    <td>{totalprcie} AED</td>
                  </tr> : ''}
                {(pickup_charge) ? <tr>
                  <th>Pickup Charge</th>
                  <td>{pickup_charge} AED</td>
                </tr> : ''}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total Payment</th>
                  <td> {(totalPay) ? totalPay : totalp} AED</td>
                </tr>
              </tfoot>
            </table>
            <div className="applyPromocode">
              <p className="formLabel">Apply Promocode</p>
              <div className="applyPromocodeFileds">
                <Input type="text" onChange={this.handlePromo} value={this.state.promo_code} name="promocode" className="form-control"></Input>
                <Button type="button" onClick={this.promoSumbit}>Apply</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="addReqSubmit">
          <Link to="#" onClick={() => this.handleSubmit((totalPay) ? totalPay : totalp)} className="btn btn-primary">Next</Link>
        </div></Spin>
      </div>

    );
  };
}
