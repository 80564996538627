import { connect } from 'react-redux'
import { getAllServices, getServicesbyID} from '../../../store/service/duck'
import {UpdateRequests, getRequestbyId } from '../../../store/request/duck'
import PaymentMethod from './component'

const PaymentMethodContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    getServicesbyID,
    UpdateRequests,
    getRequestbyId
  }
)(PaymentMethod)

export default PaymentMethodContainer