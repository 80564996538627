import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import Picker from 'react-mobile-picker';
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Spin, message, Button, Calendar, Collapse } from 'antd';
import moment from 'moment';

const { Panel } = Collapse;
// const FinalFeesBreakdown = () => {


export default class CollectionTimings extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      valueGroups: {
        hours: '11',
        minutes: '15',
        AM: 'AM'
      },
      optionGroups: {
        hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        minutes: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
        AM: ['AM', 'PM']
      },
      pickup_time: '11:15 AM',
      pickup_date: moment().add(1, 'days').format("D MMMM  YYYY"),
      value: '',
      loading: false
    }
  }


  async componentDidMount() {
    const service_id = window.localStorage.getItem('service_id')
    if (!service_id) {
      this.props.history.push('/home');
    }
    this.setState({ loading: true });
    const request_id = window.localStorage.getItem('request_id');
    if (request_id) {
      const reqobj = {
        service_req_id: request_id
      }
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {
        // console.log(datar, 'jhgj');
        this.setState({ all_request_data: datar.value.data });
        var alldata = datar.value.data;
        if (alldata.request_type === 'pickup') {
          // console.log(alldata.request_type)
          if (alldata.pickup_time) {
            this.setState({ pickup_time: alldata.pickup_time })
          }
          if (alldata.pickup_date) {
            this.setState({ pickup_date: moment(alldata.pickup_date).format("D MMMM  YYYY") })
          }
        }
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
      // selectedValue: {valueGroups}
    }), () => {
      this.setState(({ pickup_time: this.state.valueGroups.hours + ':' + this.state.valueGroups.minutes + ' ' + this.state.valueGroups.AM }))
    });
  };

  handleClick = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
      // selectedValue: {valueGroups}
    }), () => {
      this.setState(({ pickup_time: this.state.valueGroups.hours + ':' + this.state.valueGroups.minutes + ' ' + this.state.valueGroups.AM }))
    });
  };

  onPanelChange = (value, mode) => {
    // console.log(value, mode);
    // console.log(value.format('D MMMM  YYYY'), '00000')
    this.setState({ value: value });
    let datafromate = moment(value).format("D MMMM  YYYY");
    // console.log(moment(value).format("YYYY-MM-DD"))
    if (datafromate) {
      this.setState({ pickup_date: datafromate });
    }
  }

  handleSubmit = async () => {
    // this.setState({ loading: true });
    const { pickup_date, pickup_time, value } = this.state
    var is_submit = true
    var isdate;
    if (pickup_date === '') {
      is_submit = false
    }
    if (pickup_time === '' || pickup_time.trim() === '') {
      is_submit = false
    }

    if (value) {
      isdate = moment(value).format("YYYY-MM-DD")
    } else {
      isdate = moment().add(1, 'days').format("YYYY-MM-DD")
    }

    const request_id = window.localStorage.getItem('request_id')
    const userRole = window.localStorage.getItem('role')

    if (is_submit === true) {

      if (userRole == 4) {  //check guest login

        this.props.history.push('/final-fees-breakdown');

        return false;
      }

      const reqData = {
        service_req_id: request_id,
        pickup_date: isdate,
        pickup_time: pickup_time,
      }

      const datasuccess = await this.props.UpdateRequests(reqData);
      if (datasuccess.value.status === 200) {
        // message.success(datasuccess.value.msg);
        setTimeout(() => {
          this.props.history.push('/final-fees-breakdown');
          // this.setState({ loading: false, visible: false });
        }, 1000);
      } else {
        message.error(datasuccess.value.msg);
      }

    } else {
      message.error("Please insert all Fields");
    }
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  render() {
    const { optionGroups, valueGroups, pickup_date, pickup_time } = this.state;
    // console.log(pickup_date, pickup_time, 'oooo')
    return (
      <div><Spin spinning={this.state.loading} delay={500}><form>
        <div className="addRequestInner">
          <div className="addReqContainer">
            <h5 className="heading_5">Please select the date and time on which documents can be collected by us</h5>
            <div className="collectionTimeCollapse">
              <Collapse accordion>
                <Panel header="Selected Date" key="1" extra={pickup_date}>
                  <div className="documentCollectCalendar">
                    <Calendar disabledDate={this.disabledDate} fullscreen={false} onPanelChange={this.onPanelChange} />
                  </div>
                </Panel>
                <Panel header="Selected Time" key="2" extra={pickup_time}>
                  <Picker
                    optionGroups={optionGroups}
                    valueGroups={valueGroups}
                    onChange={this.handleChange}
                    // onClick={this.handleClick} 
                    />
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="addReqSubmit">
          <div className="addressNext">
            <Button type="submit" onClick={this.handleSubmit} className="btn btn-primary"><img src="./images/icons/arrow-right.png" /></Button>
          </div>
        </div></form></Spin>
      </div>
    );
  };
}
