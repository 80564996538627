import { connect } from 'react-redux'
import { getAllServices, uploadImage, getServicesbyID} from '../../store/service/duck'
import { addRequests, UpdateRequests, getRequestbyId } from '../../store/request/duck'
import AddRequest from './component'

const AddRequestContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    uploadImage,
    getServicesbyID,
    addRequests,
    UpdateRequests,
    getRequestbyId
  }
)(AddRequest)

export default AddRequestContainer