import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { message } from 'antd'

import "./styles.scss";
import { Link } from "react-router-dom";

export default class AffiliateComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      err: {},
      password: '',
      code: '',
      message: '',
      disable: true,
      signInButtonDisable: true,
      loginMessage: true,
      messageerr: '',
      hidden: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.loginUser = this.loginUser.bind(this)
    this.toggleShow = this.toggleShow.bind(this);
    this.guestUser = this.guestUser.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.email === '' || self.state.email === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount () {
    document.title = 'Affiliate Login | DBC';
    const params = this.props.match.params
    localStorage.setItem('affiliate', params.id)
    // console.log(params.id, 'aaaa')
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.loginPhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {
          //set token
          localStorage.setItem('token', this.props.user.data.token)

          //set role 2 for Affilate and 3 for customer
          localStorage.setItem('role', this.props.user.data.role)
          localStorage.setItem('username', this.props.user.data.user_name)

          //console.log(this.props.user);
          this.setState({ email:"", password:"" });
          this.props.history.push('/home')
        }else{
         // console.log(this.props.user.msg)
          this.setState({messageerr:this.props.user.msg})
          //localStorage.setItem('token', '')
          localStorage.removeItem('token')
        }
      }
    }
  }


  loginUser () {
    const { email, password } = this.state
    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (email === '' || email.trim() === '') {
      err.email = 'Email is required.'
    }

    if (password === '' || password.trim() === '') {
      err.password = 'Password is required.'
    }

    if (email && email !== '') {
      const testR = emailRegex.test(String(this.state.email).toLowerCase())
      if (!testR) {
        err.email = 'Email is invalid.'
      }
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (email && password) {
        const loginData = {
          email: this.state.email,
          password: this.state.password
        }
        this.props.loginUser(loginData)
      } else {
        message.error('Please insert valid input')
      }
    }
  }

  guestUser () {
    //set role 4 for guest user
    localStorage.setItem('role', 4)

    //redirect on home page
    this.props.history.push('/home')
  }

  render() {
    const affiliate_id = window.localStorage.getItem('affiliate')
    return (
      <div>
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="../images/logo.png"/>
          </div>
          <div className="authForm">
            
              <h4>Sign in</h4>

              { this.state.messageerr ?
                    <span className="invalid-feedback alert alert-danger">
                      {this.state.messageerr}
                    </span>
                    : ''
                  }

              <p className="authSubheading">Hello there, sign in to continue!</p>
              <div className="form-group">
                <p className="formLabel">Email</p>
                <input type="text" placeholder="Enter your email" className="form-control" name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)} 
                  required/>
                   { this.state.err.email ?
                    <span className="invalid-feedback">
                      {this.state.err.email}
                    </span>
                    : ''
                  }
              </div>
              <div className="form-group">
                <p className="formLabel">Password</p>
                <div className="pass_field">
                  <input type={this.state.hidden ? "password" : "text"} placeholder="Enter Password" className="form-control" name="password"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)} 
                  required/>
                  { this.state.err.password ?
                    <span className="invalid-feedback">
                      {this.state.err.password}
                    </span>
                    : ''
                  }

                  <button className="showPass" onClick={this.toggleShow}><img src="../images/eye.png"/></button>
                </div>
              </div>
              <div className="forgotPLink">
                <Link to="/affiliate-login/id/forgot-password">Forgot Password?</Link>
              </div>
              <button type="submit" className="btn btn-primary" onClick={this.loginUser} onKeyDown={this.loginUser}>Sign In</button>
              <div className="formFooter">
              {/* <p>Don’t have an account? <Link to="signup">Sign up</Link></p> */}
              {/* <button className="btn btn-asGuest" onClick={this.guestUser}>Continue as a guest <img src="./images/arrow-left.png"/></button> */}
              </div>
            
          </div>
        </div>
        </div>
      </div>
    );
  }
}

