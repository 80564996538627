// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const GET_ALL_REQUEST = 'dbc/request/GET_ALL_REQUEST'
export const Add_REQUEST = 'dbc/request/Add_REQUEST'
export const UPDATE_REQUEST = 'dbc/request/UPDATE_REQUEST'
export const GET_REQUEST_BY_ID = 'dbc/request/GET_REQUEST_BY_ID'
export const GET_REQUEST_PAY = 'dbc/request/GET_REQUEST_PAY'
export const GET_REQUEST_TRANSACTION = 'dbc/request/GET_REQUEST_TRANSACTION'
export const GET_PROMOCODE = 'dbc/request/GET_PROMOCODE'
export const CREAT_PDF = 'dbc/request/CREAT_PDF'
export const GET_TRACKING_DATA = 'dbc/request/GET_TRACKING_DATA'
export const GET_REQUEST_TRANSACTION_BY_FILTER = 'dbc/request/GET_REQUEST_TRANSACTION_BY_FILTER'
export const GET_ALL_UNREAD_MESSAGES = 'dbc/request/GET_ALL_UNREAD_MESSAGES'
export const GET_ALL_CHAT_HISTORY = 'dbc/request/GET_ALL_CHAT_HISTORY'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const getAllRequests = payload => {
  return {
    type: GET_ALL_REQUEST,
    payload: api.getAllRequests(payload)
  }
}

export const addRequests = payload => {
  return {
    type: Add_REQUEST,
    payload: api.addRequests(payload)
  }
}

export const UpdateRequests = payload => {
  return {
    type: UPDATE_REQUEST,
    payload: api.UpdateRequests(payload)
  }
}

export const getRequestbyId = payload => {
  return {
    type: GET_REQUEST_BY_ID,
    payload: api.getRequestbyId(payload)
  }
}

export const getRequestPay = payload => {
  return {
    type: GET_REQUEST_PAY,
    payload: api.getRequestPay(payload)
  }
}

export const getRequesTransaction = payload => {
  return {
    type: GET_REQUEST_TRANSACTION,
    payload: api.getRequesTransaction(payload)
  }
}

export const getPromoCode = payload => {
  return {
    type: GET_PROMOCODE,
    payload: api.getPromoCode(payload)
  }
}

export const createPdf = payload => {
  return {
    type: CREAT_PDF,
    payload: api.createPdf(payload)
  }
}

export const getTrackingData = payload => {
  return {
    type: GET_TRACKING_DATA,
    payload: api.getTrackingData(payload)
  }
}

export const getTransactionByfilter = payload => {
  return {
    type: GET_REQUEST_TRANSACTION_BY_FILTER,
    payload: api.getTransactionByfilter(payload)
  }
}


export const getAllUnreadMessages = payload => {
  return {
    type: GET_ALL_UNREAD_MESSAGES,
    payload: api.getAllUnreadMessages(payload)
  }
}

export const getAllChatHistory = payload => {
  return {
    type: GET_ALL_CHAT_HISTORY,
    payload: api.getAllChatHistory(payload)
  }
}


/***********************************
 * Epics
 ***********************************/

export const requestEpic = combineEpics()