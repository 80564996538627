import { connect } from 'react-redux'
import { getAllServices, getServicesbyID} from '../../../store/service/duck'
import {UpdateRequests, getRequestbyId, getPromoCode } from '../../../store/request/duck'
import {userSetting} from '../../../store/user/duck'
import FinalFeesBreakdown from './component'
import { fromJS } from 'immutable'

const FinalFeesBreakdownContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    getServicesbyID,
    UpdateRequests,
    getRequestbyId,
    getPromoCode,
    userSetting
  }
)(FinalFeesBreakdown)

export default FinalFeesBreakdownContainer