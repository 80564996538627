import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { message } from 'antd'
import "./styles.scss";
import { Link } from "react-router-dom";

export default class SignupDetailComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      confirm_password: '',
      term_condition: false,
      err: {},
      message: '',
      disable: true,
      messageerr: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.signupDetail = this.signupDetail.bind(this)
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {

      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }

      if (self.state.confirm_password === '' || self.state.confirm_password === undefined) {
        disable = true
      }

      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'SignUp Detail | DBC'
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.signupDetailPhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {
          //console.log(this.props.user);
          this.props.history.push('/home')
        }else{
          //console.log(this.props.user.msg)
          this.setState({messageerr:this.props.user.msg})
        }
      }
    }
  }

   handleChangeTermCond({target}){
        if (target.checked){
          this.setState({term_condition:true})
        } else {
          this.setState({term_condition:false})
        }
    }


  signupDetail () {
    const { password, confirm_password, term_condition} = this.state
    
    const err = {}

    if (password === '' || password.trim() === '') {
      err.password = 'Password is required.'
    }

    if (confirm_password === '' || confirm_password.trim() === '') {
      err.confirm_password = 'Confirm password is required.'
    }

    if(password !== ''){
      if(password.length < 5){
        err.password = 'Password length must be 6 or greater than'
      }
    }


    if(password !== '' && confirm_password !== ''){
      if(password !== confirm_password){
        err.confirm_password = 'Password and confirm password did not match.'
      }
    }

    if (term_condition === false) {
      err.term_condition = 'Accept term and condition for continue.'
    }


    
    this.setState({ err })
    if (!Object.keys(err).length) {
      
      if (password && confirm_password) {
        const signupDetailData = {
          password: this.state.password,
        }
        this.props.signupDetail(signupDetailData)
      } else {
        message.error('Please insert valid input')
      }
    }
  }

  render() {

    return (
      <div>
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="./images/logo.png"  alt="Logo"/>
          </div>
          <div className="authForm">
           
              <h4>Complete Details</h4>

              { this.state.messageerr ?
                <span className="invalid-feedback">
                  {this.state.messageerr}
                </span>
                : ''
              }


              <p className="authSubheading">Please complete your profile</p>
              <div className="form-group">
                <p className="formLabel">Password</p>
                <input type="password" placeholder="Enter your password" className="form-control" name="password"
                    value={this.state.password}
                    onChange={this.handleChange.bind(this)} 
                    minLength="6"/>
                     { this.state.err.password ?
                      <span className="invalid-feedback">
                        {this.state.err.password}
                      </span>
                      : ''
                    }
              </div>
              <div className="form-group">
                <p className="formLabel">Confirm password</p>
                <input type="password" placeholder="Confirm your password" className="form-control" name="confirm_password"
                    value={this.state.confirm_password}
                    onChange={this.handleChange.bind(this)} 
                    minLength="6"/>
                     { this.state.err.confirm_password ?
                      <span className="invalid-feedback">
                        {this.state.err.confirm_password}
                      </span>
                      : ''
                    }
              </div>
              <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck" name="term_condition"
                    value={this.state.term_condition}
                    onChange={this.handleChangeTermCond.bind(this)} 
                    required/>
                     
              <label class="custom-control-label" for="customCheck">By creating an account, you aggree to our <Link>Term and Conditions</Link></label>

              { this.state.err.term_condition ?
                      <span className="invalid-feedback">
                        {this.state.err.term_condition}
                      </span>
                      : ''
                    }
            </div>

               <button type="submit" className="btn btn-primary" onClick={this.signupDetail} onKeyDown={this.signupDetail}>Sign up</button>
           
              <div className="formFooter">
              <p>Already have an account? <Link to={'/signin'}>Sign In</Link></p>
              </div>
            
          </div>
        </div>
        </div>
      </div>
    );
  }
}

