import React , { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import Map  from "./Map/container";
// import Map from "../views/AddRequest/CollectionType/container";
import "./styles.scss";
import 'antd/dist/antd.css';
import {Input, Button, message } from 'antd';
// import Autocomplete from 'react-google-autocomplete';


// const Address = () => {
  const mapStyles = {
    width: '100%',
    height: '100%'
  };
export default class Address extends PureComponent {  
  constructor(props) {
    super(props);
    this.state = {
      goclick: '',
      service_databyId: [],
      err: {},
      message: "",
      disable:false,
      selectedItem: [],
      loading:false,
      address:'',
      landmark:'',
      latValue:'',
      lngValue:''
    };

    this.handleChanged = this.handleChanged.bind(this);

  }

  async componentDidMount() {
    const service_id = window.localStorage.getItem('service_id')
    if(!service_id){
      this.props.history.push('/home');
    }
    
    const request_id = window.localStorage.getItem('request_id');
    const reqobj = {
      service_req_id: request_id
    }
    const datar = await this.props.getRequestbyId(reqobj);
    if (datar.value.status === 200) {
      // console.log(datar, 'jhgj');
      this.setState({ landmark: datar.value.data.landmark, address: datar.value.data.address });
    }
  }

  showDetail(){
    this.setState({ goclick: 'ShowDetailForm' });
  }
  handleChanged(e){
    // e.preventDefault();
    e.stopPropagation();
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.address === '' || self.state.address === undefined) {
        disable = true
      }
      if (self.state.landmark === '' || self.state.landmark === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  handleSubmit=async()=>{
    // this.setState({ loading: true });
    const { address, landmark, latValue, lngValue } = this.state
    // console.log(address, 'lplplplpllplplplpl')
    const err = {};
    if (address === '' || address.trim() === '') {
      err.address = 'address is required.'
    }
    if (landmark === '' || landmark.trim() === '') {
      err.landmark = 'landmark is required.'
    }

    this.setState({ err })

    const userRole = window.localStorage.getItem('role')

    if (!Object.keys(err).length) {

        if(userRole == 4){   // guest login check
          setTimeout(() => {
            this.props.history.push('/collection-type');
            // this.setState({ loading: false, visible: false });
          }, 1000);
        }else{

          const request_id = window.localStorage.getItem('request_id')

          if (address && landmark) {
            const reqData = {
              service_req_id: request_id,
              address: address,
              landmark: landmark,
              lat:latValue,
              lng:lngValue
            }
          const datasuccess = await this.props.UpdateRequests(reqData);
          if (datasuccess.value.status === 200) {
            // message.success(datasuccess.value.msg);
            setTimeout(() => {
              this.props.history.push('/collection-type');
              // this.setState({ loading: false, visible: false });
            }, 1000);
          } else {
            message.error(datasuccess.value.msg);
          }
        } else {
          message.error("Please insert all Fields");
        }
     }
    }
  }

  handleAddress = (address) => {
    this.setState({address: address});
  }

  handlelatValue = (latValue) => {
    this.setState({latValue: latValue});
  }

  handlelngValue = (lngValue) => {
    this.setState({lngValue: lngValue});
  }

  render(){
  const{ address, landmark, err, lngValue, latValue }=this.state
  // console.log(address, 'helll', latValue, "latValue", lngValue, "lngValue")
  return (
      <div>
      <div className="addRequestInner">
          <div className="addReqContainer">
            <div className="addressMap">
            <div className="mapAddressSearch">
              <img src="./images/icons/location.png"/>
              <div className="mapAddressField">
                <p>(Only you will see this address)</p>
              </div>
            </div>
            <Map
            google={this.props.google}
            center={{lat: 25.113352, lng: 55.2027073}}
            zoom={14}
            onSelectAddress={this.handleAddress}
            latValue ={this.handlelatValue}
            lngValue ={this.handlelngValue}
            // {...this.props}
            />
            </div>
          </div>
      
      <div className="addReqSubmit">
      <div className="addressNext">
        <Button to="/" className="btn btn-primary" onClick={() => this.showDetail()}><img src="./images/icons/arrow-right.png"/></Button>
      </div>
      </div>
      <div className={this.state.goclick === 'ShowDetailForm' ? 'ShowDetailForm' : ''}>
        <div className="addressMoreDetailMain">
          <div className="addressMoreDetail">
              <h4>More Details</h4>
              <div className="form-group">
                <p className="formLabel"><img src="./images/icons/home.png"/> House/Flat No.</p>
                {/* <Autocomplete
                    style={{width: '90%'}}
                    onPlaceSelected={(place) => {
                      console.log(place);
                    }}
                    types={[]}
                    componentRestrictions={{country: "ru"}}
                /> */}
                <Input required type="text" name="address" value={(address)? address: ''} onChange={this.handleChanged} className="form-control" placeholder="eg., Naruka Homes / B-53"/>
                { err.address ?
                    <span className="invalid-feedback">
                      {err.address}
                    </span>
                    : ''
                  }
              </div>
              <div className="form-group">
                <p className="formLabel"><img src="./images/icons/landmark.png"/> Landmark</p>
                <Input required type="text" name="landmark"  className="form-control" value={(landmark)? landmark: ''}  onChange={this.handleChanged} placeholder="eg., Opposite to HDFC Bank"/>
                { err.landmark ?
                    <span className="invalid-feedback">
                      {err.landmark}
                    </span>
                    : ''
                  }
              </div> 
              <Button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Next</Button>
          </div>
        </div>
      </div>
      </div>
      </div>
  )
};
}
// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCFR9hzxNNeMLv8TY_kfKgHBEFiBrG5dXE'
// })(Address);
