import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import moment from 'moment';
import { Spin } from "antd";
import 'antd/dist/antd.css';

export default class Receipt extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      visible: true,
      confirmLoading: false,
      all_request_data: [],
      transaction_data: {},
      requestData: [],
      pdfLink:'',
      loading: false
    }
  }


  async componentDidMount() {
    document.title = 'Receipt | DBC'
    this.setState({loading: true})
    const request_id =  this.props.match.params.id;
    //  console.log(id,'hjghjg')
    // const request_id ='5e9af58260c4df31a47c28ee'
    const service_req = {
      service_req_id: request_id,
    }

    const datareq = await this.props.getRequestbyId(service_req);
    if (datareq.value.status === 200) {
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ requestData: datareq.value.data, loading: false });
    }

    if (this.state.requestData.payment_method === 'card') {
      const reqobj = {
        service_req_id: request_id,
        page_no: 0
      }
      const datar = await this.props.getRequestPay(reqobj);
      if (datar.value.status === 200) {
        // console.log(datar.value.data, 'helllooo');
        this.setState({ all_request_data: datar.value.data, transaction_data: JSON.parse(datar.value.data.transaction_data) , loading: false});
      }else if (datar.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }
    }
    const reqPdf = {
      service_req_id: request_id,
    }
    const dataPDF = await this.props.createPdf(reqPdf);
    if (dataPDF.value.status === 200) {
      this.setState({ pdfLink: dataPDF.value.data, loading: false });
    }else if (dataPDF.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }

    this.setState({loading: false})
  }


  render() {
    const {pdfLink, all_request_data, transaction_data, requestData } = this.state;
    const username = window.localStorage.getItem('username')
    return (
      <div>
      <Spin spinning={this.state.loading} delay={500}>
          <div className="profilePages">
            <div className="profile-withbottomButton">
              <h1 className="profileHeading"><Link to={`/track/${this.props.match.params.id}`}><img src="../images/icons/left.png" /> Receipt</Link></h1>
              <div className="profile-container">
                <div className="requestAmount">
                <h4>{(all_request_data.payment_method === 'card') ? all_request_data.total_payment : requestData.total_premium} AED</h4>
                  <p>Request Amount</p>
                </div>
                <table className="table thanksModalTable">
                <tbody>
                  <tr>
                    <td>
                      <h5>From:</h5>
                      <p>{(all_request_data.payment_method === 'card') ? transaction_data.card_type + ' ' + all_request_data.payment_method : requestData.payment_method}  </p>
                    </td>
                    <td>
                      <h5>{username}</h5>
                      <p>{(all_request_data.payment_method === 'card') ? transaction_data.scheme + '   *' + transaction_data.last4 : ''}  </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Date:</h5>
                    </td>
                    <td>
                      <p>{(all_request_data.payment_method === 'card') ? moment(all_request_data.create_on).format("D MMMM  YYYY") : moment(requestData.create_on).format("D MMMM  YYYY") }  </p>
                      {/* <p>12:48 PM</p> */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="downloadReceipt">
            <a href={`https://dbcapi.webvilleedemo.xyz/uploads/${pdfLink}`}  target="_blank" download>
            <img src="../images/icons/icon-bill.png" />Download Receipt</a>
            {/* <Link to="" download></Link> */}
          </div>
              </div>
            </div>
            <div className="profileBottomButton">
              <Link to="/" className="btn btn-primary">Home</Link>
            </div>
          </div>
          {/* <div className="footerMenuWrap">
            <div className="menuMain">
                <ul>
                <li><a href="/"><img src="../images/icons/menu-1.png" alt="menu" /></a></li>
                <li><a href="/tracking"><img src="../images/icons/menu-active-3.png" alt="menu" /></a></li>
                <li className="/menuPlus"><a href="/add-request/new_request"><img src="../images/icons/plus.png" alt="menu" /></a></li>
                <li><a href="/transactions"><img src="../images/icons/menu-2.png" alt="menu" /></a></li>
                <li><a href="/menu"><img src="../images/icons/menu-4.png" alt="menu" /></a></li>
              </ul>
            </div>
          </div>     */}
          </Spin>
        </div>

    );
  }
}
