import { connect } from 'react-redux'
import TrackingComponent from './component'
import {  loginUser, userClearPhase, codeClearPhase} from '../../store/user/duck'
import { getRequesTransaction } from '../../store/request/duck'

const TrackingContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    loginPhase: state.user.loginPhase
  }),
  {
    loginUser,
    userClearPhase,
    codeClearPhase,
    getRequesTransaction
  }
  // Map actions to dispatch and props
)(TrackingComponent)

export default TrackingContainer
