import { connect } from 'react-redux'
import { getAllServices, uploadImage, documentRemove, getServicesbyID} from '../../../store/service/duck'
import { UpdateRequests, getRequestbyId, getRequestPay, createPdf } from '../../../store/request/duck'
import ThankYou from './component'

const ThankYouContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    uploadImage,
    getServicesbyID,
    UpdateRequests,
    getRequestbyId,
    documentRemove,
    getRequestPay,
    createPdf
  }
)(ThankYou)

export default ThankYouContainer