import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import {Input, Select } from 'antd';

// const CollectionType = () => {
  const { Option } = Select;
export default class DocumentMethod extends PureComponent {  
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    const service_id = window.localStorage.getItem('service_id')
    if(!service_id){
      this.props.history.push('/home');
    }
  }

  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }
  render() {
    const collectionType = this.state.value === 'pickup';
  return (
      <div>
      <div className="addRequestInner">
        <div className="addReqContainer">    
            <div className="customRadio">
              <Input type="radio" name="documentMethod" value="whole-pickup" onChange={this.handleChange}/>
              <div className="customRadioContent">
                <img src="./images/icons/whole-pickup.png"/>
                <div className="cr_rightContent">
                  <h4>Whole Pickup</h4>
                  <p>We will collect your documents at your provided address</p>
                </div>
              </div>
            </div>
            <div className="customRadio">
              <Input type="radio" name="documentMethod"  value="upload" onChange={this.handleChange}/>
              <div className="customRadioContent">
                <img src="./images/icons/upload.png"/>
                <div className="cr_rightContent">
                  <h4>Upload</h4>
                  <p>You can upload some eligible document here as well.</p>
                </div>
              </div>
            </div>
            {this.state.value === 'upload' && (
                <p className="noteTxt">
                  <strong>Note : </strong>
                  <span> You need to provide the documents in the color copy in pdf format</span>
                </p>
            )}
            </div>
            </div>
        <div className="addReqSubmit">
          {this.state.value !== 'upload' && (
          <Link to="/" className="btn btn-primary">Next</Link>
          )}
          {this.state.value === 'upload' && (
          <Link to="/" className="btn btn-primary">Upload</Link>
          )}
        </div>
      </div>

  );
};
}

// export default CollectionType;
