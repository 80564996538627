import { connect } from 'react-redux'
import { UpdateRequests, getRequestbyId,  } from '../../../store/request/duck'
import Address from './component'

const AddressContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    UpdateRequests,
    getRequestbyId
  }
)(Address)

export default AddressContainer