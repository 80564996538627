import { connect } from 'react-redux'
import { getServicesbyID } from '../../../store/service/duck'
import { UpdateRequests, getRequestbyId } from '../../../store/request/duck'
import { getUserDetails } from '../../../store/user/duck'
import PaymentProcessing from './component'

const PaymentProcessingContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getServicesbyID,
    getRequestbyId,
    getUserDetails,
    UpdateRequests
  }
)(PaymentProcessing)

export default PaymentProcessingContainer