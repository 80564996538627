import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Progress, message } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export default class PaymentProcessing extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      requestData: []
    }
  }

  async componentDidMount() {
    const service_id = window.localStorage.getItem("service_id");
    if (!service_id) {
      this.props.history.push("/home");
    }
    var userdata = ''
    const getData = await this.props.getUserDetails();
    if (getData.value.status === 200) {
      // console.log(getData.value.data, 'ppp');
      userdata = getData.value.data
    }else if (getData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
    const request_id = window.localStorage.getItem('request_id');
    // const request_id ="5e9ee678c2b25226844d7fca";
    const service_req = {
      service_req_id: request_id,
    }
    const datareq = await this.props.getRequestbyId(service_req);
    if (datareq.value.status === 200) {
      // console.log(datareq.value.data, 'uuuuuuuuu');
      const requestAllData = datareq.value.data;
      this.setState({ requestData: datareq.value.data });
      var scheduled_date = new Date(requestAllData.pickup_date_timestamp).toISOString();

      if (requestAllData.request_type === "pickup") {
        fetch("https://api.staging.quiqup.com/oauth/token", {
          "method": "POST",
          "headers": {
            "Content-Type": "application/json",
            "accept": "application/json"
          },
          "body": JSON.stringify({
            "grant_type": "client_credentials",
            "client_id": "7d267325aee321c82db3dd3fbbe3fd5949ef22975c8f22b8147bcff4d93ab581",
            "client_secret": "695233d31d995e21d9f4838162dffeba63990648c0001d7a5ecc3f157a81c6e8"
          })
        })
          .then(response => response.json())
          .then(response => {
            // console.log(response, 'response')
            if (response.access_token) {
              // console.log(response, scheduled_date);
              fetch("https://api.staging.quiqup.com/partner/jobs", {
                "method": "POST",
                "headers": {
                  "Authorization": `Bearer ${response.access_token}`,
                  "Content-Type": "application/json",
                  "accept": "application/json"
                },
                "body": JSON.stringify(
                  {
                    "kind": "partner_same_day",
                    "scheduled_for": scheduled_date,
                    "pickups": [
                      {
                        "contact_name": userdata.user_name,
                        "contact_phone": "+971525557717",
                        "partner_order_id": "XYZ123456",
                        "location": {
                          "address1": requestAllData.address,
                          "coords": [
                            requestAllData.lng,
                            requestAllData.lat
                          ],
                          "country": "UAE",
                          "town": "Dubai",
                          "notes": "Go directly to the address"
                        },
                        "items": [
                          {
                            "name": `Document pickup for ${requestAllData.service_name} service and AWB number is ${requestAllData._id}  `,
                            "quantity": 1,
                          }
                        ]
                      }
                    ],
                    "dropoffs": [
                      {
                        "contact_name": "Dubai Al Makateb",
                        "contact_phone": "+971507558090",
                        "share_tracking": true,
                        "payment_amount": 0,
                        "location": {
                          "address1": "Exec Pro Business Management, Office 205",
                          "coords": [
                            55.3326906,
                            25.2577261
                          ],
                          "country": "UAE",
                          "town": "Dubai"
                        }
                      }
                    ]

                  }
                )
              })
                .then(res => res.json())
                .then(async (res) => {
                  // console.log(res, '0000')
                  if (res.id) {
                    fetch(`https://api.staging.quiqup.com/partner/jobs/${res.id}/submissions`, {
                      "method": "POST",
                      "headers": {
                        "Authorization": `Bearer ${response.access_token}`,
                        "Content-Type": "application/json",
                        "accept": "application/json"
                      },
                      "body": JSON.stringify(
                        {
                          "state": "pending_assignment",
                          "earliest_collection_at": scheduled_date
                        }
                      )
                    })
                      .then(resp => resp.json())
                      .then(async (resp) => {
                        // console.log(resp, '77887')
                        if (resp.state) {
                          const reqData = {
                            service_req_id: request_id,
                            trackingIdPickup: res.id
                          }
                        const datasuccess = await this.props.UpdateRequests(reqData);
                        if (datasuccess.value.status === 200) {
                          this.props.history.push('/thank-you');
                        }else if (datasuccess.value.status === 401) {
                          localStorage.clear();
                          this.props.history.push('/signin');
                        } else {
                          message.error(datasuccess.value.msg);
                        }
                        } else {
                          // console.log(resp, 'ttt76876rtr')
                          // message.error(res.response_code + ' please retry');
                          // message.error("Invalid card details please check");
                          // this.setState({ loading: false });
                        }
                      })
                      .catch(error => {
                        message.error(error);
                      });

                    // setTimeout(() => {
                    //   this.props.history.push('/thank-you');
                    // }, 3000);
                  } else {
                    // console.log(res, 'tttrtr')
                    // message.error(res.response_code + ' please retry');
                    // message.error("Invalid card details please check");
                    // this.setState({ loading: false });
                  }
                })
                .catch(error => {
                  message.error(error);
                });
            } else {
              message.error(response.error + ' please recheck');
              this.setState({ loading: false });
            }

          })
          .catch(err => {
            message.error(err);
          });
      } else {
        this.props.history.push('/thank-you');
      }

    } else if (datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }

  }

  render() {
    const {requestData} = this.state
    return (
      <div className="paymentProcessing ">
        <div className="addReqContainer">
          <h1>Please wait …</h1>
          <p>Please don’t press cancel while we are processing your  {(requestData.payment_method==="cash")? 'order' : 'payment'}</p>
          <div className="paymentProgress">
            <Progress percent={75} strokeColor="#0B3757" strokeLinecap="round" />
          </div>
        </div>
        <div className="imageWrap">
          <img src="./images/card.png" />
        </div>
      </div>
    );
  };
}

