import { connect } from 'react-redux'
import {  getRequestbyId, getRequestPay, createPdf } from '../../store/request/duck'
import ThankYou from './component'

const ThankYouContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getRequestbyId,
    getRequestPay,
    createPdf
  }
)(ThankYou)

export default ThankYouContainer