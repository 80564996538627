import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";

const logout = () => {
  localStorage.clear();
};

export default class HomeComponent extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageerr: ''
    }
  }


  componentDidMount() {
    document.title = 'Menu | DBC'
  }


  render() {
    return (
      <div>
        <div className="pageWithMenu">
          <div className="profilePages">
            <h1 className="profileHeading">Profile</h1>
            <div className="profileMenu">
              <ul>
                <li>
                  <Link to="profile"><img src="./images/icons/profile.png" alt="Profile" /><span>My Profile</span></Link></li>
                  <li><Link to="tracking"><img src="./images/icons/tracking.png"  alt="Tracking"/><span>Tracking</span></Link></li>
                  <li><Link to="transactions"><img src="./images/icons/transaction.png"  alt="Transactions"/><span>Transactions</span></Link></li>
                  {/* <li><Link to="#"><img src="./images/icons/rate.png" alt="rate"/><span>Rate the app</span></Link></li> */}
                  <li><Link to="partner"><img src="./images/icons/partner.png"alt="partner" /><span>Partner with us</span></Link></li>
                  <li><Link to="privacy"><img src="./images/icons/privacy.png" alt="privacy" /><span>Privacy</span></Link></li>
                  <li><Link to="support"><img src="./images/icons/help.png" alt="help" /><span>Support & Help</span></Link></li>
                  <li><a onClick={logout} href="/signin" ><img src="./images/icons/logout.png" alt="logout" /><span>Logout</span></a></li>
              </ul>
            </div>
          </div>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><Link to="/"><img src="./images/icons/menu-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-3.png" alt="menu"/></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu"/></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-active-4.png" alt="menu"/></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
