import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { message } from 'antd'

import "./styles.scss";
// import { Link } from "react-router-dom";a

import { Link } from "react-router-dom";

export default class ForgotPasswordComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      errorMessageClass: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.email === '' || self.state.email === undefined) {
        disable = true
      }
      
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'Forgot Password | DBC'
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.forgotPasswordPhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          this.setState({messageerr:this.props.user.msg})
          this.setState({errorMessageClass:false})

        }else{
         // console.log(this.props.user.msg)
          this.setState({messageerr:this.props.user.msg})
          this.setState({errorMessageClass:true})
        }
      }
    }
  }


  forgotPassword () {
    const { email } = this.state
    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (email === '' || email.trim() === '') {
      err.email = 'Email is required.'
    }

    if (email && email !== '') {
      const testR = emailRegex.test(String(this.state.email).toLowerCase())
      if (!testR) {
        err.email = 'Email is invalid.'
      }
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (email) {
        const forgotPasswordData = {
          email: this.state.email,
        }
        this.props.forgotPassword(forgotPasswordData)
      } else {
        message.error('Please insert valid input')
      }
    }
  }

  render() {
    return (
      <div>
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="./images/logo.png"/>
          </div>
          <div className="authForm">
            
              <h4>Forgot Password</h4>

              { this.state.messageerr ?<div className="alert alert-danger">
                <span className={this.state.errorMessageClass ? "invalid-feedback" : "validFeedbackMessage"}>
                  {this.state.messageerr}
                </span>
                </div>
                : ''
              }

              <div className="form-group">
                <p className="formLabel">Email</p>
                <input type="text" placeholder="Enter your email" className="form-control" name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)} 
                  required/>
                   { this.state.err.email ?
                    <span className="invalid-feedback">
                      {this.state.err.email}
                    </span>
                    : ''
                  }
              </div>
               <button type="submit" className="btn btn-primary" onClick={this.forgotPassword} onKeyDown={this.forgotPassword}>Submit</button>
                
                <div className="formFooter">
                <p><Link to={'/signin'}>Sign In</Link></p>
                </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

