import { connect } from 'react-redux'
import SignupDetailComponent from './component'
import {  signupDetail, userClearPhase, codeClearPhase } from '../../store/user/duck'

const SignupDetailContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    signupDetailPhase: state.user.signupDetailPhase
  }),
  {
    signupDetail,
    userClearPhase,
    codeClearPhase
  }
  // Map actions to dispatch and props
)(SignupDetailComponent)

export default SignupDetailContainer

