import { connect } from 'react-redux'
import PrivacyComponent from './component'
import {getContent} from '../../store/user/duck'

const PrivacyContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getContent
  }
  // Map actions to dispatch and props
)(PrivacyComponent)

export default PrivacyContainer
