import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { message } from 'antd'

import "./styles.scss";
//import { Link } from "react-router-dom";

export default class OtpComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      messageerr1: '',
      seconds: '00',
      value: '5',
      //isClicked: true,
      errorMessageClass: true,
      resendButtonEnable: '',
      one: '',
      two: '',
      three: '',
      four: '',
      oneFocus: false,
      twoFocus: false,
      threeFocus: false,
      fourFocus: false,
     
    }
    this.handleChange = this.handleChange.bind(this)
    this.otpVerify = this.otpVerify.bind(this)
    this.resendOtp = this.resendOtp.bind(this)

    this.secondsRemaining = 0;
    this.intervalHandle = 0;
    //this.handleChange = this.handleChange.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.otp1 === '' || self.state.otp1 === undefined) {
        disable = true
      }
      if (self.state.otp2 === '' || self.state.otp2 === undefined) {
        disable = true
      }
      if (self.state.otp3 === '' || self.state.otp3 === undefined) {
        disable = true
      }
      if (self.state.otp4 === '' || self.state.otp4 === undefined) {
        disable = true
      }
      self.setState({ disable })

      if (self.state.otp1){ 
        this.refs.otp2.focus(); 
      }
      if (self.state.otp2){ 
        this.refs.otp3.focus(); 
      }
      if (self.state.otp3){ 
        this.refs.otp4.focus(); 
      }

      if(self.state.otp1 !== '' && self.state.otp2 !== '' && self.state.otp3 !== '' && self.state.otp4 !== ''){
          this.otpVerify();
      }
    })
  }

  componentDidMount() {
    document.title = 'Otp | DBC'

    this.startCountDown()

    this.refs.otp1.focus()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.sendVerificationCodePhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          //set token
          localStorage.setItem('token', this.props.user.data.token)

           //set role 2 for Affilate and 3 for customer
          localStorage.setItem('role', this.props.user.data.role)
                  
          // remove user_email after use in otp verification
          localStorage.removeItem('user_email')
          localStorage.removeItem('user_contact')
          localStorage.removeItem('user_name')
          localStorage.removeItem('std_code')


          //console.log(this.props.user);
          this.props.history.push('/signup-detail')
        }else{
          //console.log(this.props.user.msg)
          this.setState({messageerr:this.props.user.msg})
          this.setState({errorMessageClass:true})
          //localStorage.setItem('token', '')
          localStorage.removeItem('token')
        }
      }
    }
  }


  otpVerify () {

    const { otp1, otp2, otp3, otp4 } = this.state
    
    const err = {}

    // if (otp1 === '' || otp1.trim() === '') {
    //   err.otp1 = 'Required'
    // }
    // if (otp2 === '' || otp2.trim() === '') {
    //   err.otp2 = 'Required'
    // }
    // if (otp3 === '' || otp3.trim() === '') {
    //   err.otp3 = 'Required'
    // }
    // if (otp4 === '' || otp4.trim() === '') {
    //   err.otp4 = 'Required'
    // }

    
    this.setState({ err })
    if (!Object.keys(err).length) {
      if (otp1 && otp2 && otp3 && otp4) {

        this.setState({messageerr1: ''})

        const otpData = {
          otp: this.state.otp1+this.state.otp2+this.state.otp3+this.state.otp4,
          email: localStorage.getItem('user_email')

        }
        this.props.sendVerificationCode(otpData)
      } else {
        message.error('Please insert valid code')
      }
    }
  }

   resendOtp= async()=> {

    //this.setState({ messageerr1: '' });

    this.setState({otp1: ''})
    this.setState({otp2: ''})
    this.setState({otp3: ''})
    this.setState({otp4: ''})

    this.refs.otp1.focus()

    const resendOtpData = {
      email: localStorage.getItem('user_email')
    }
    
    const resData = await this.props.resendOtp(resendOtpData);
    
    if(resData.value.status === 200){
      this.setState({messageerr1: resData.value.msg})
      this.setState({errorMessageClass:false})
      this.setState({seconds:'00'})
      this.setState({value:'5'})
      clearInterval(this.intervalHandle);
      this.startCountDown()
      this.setState({resendButtonEnable:''})
    }else{
      this.setState({messageerr1: resData.value.msg})
      this.setState({errorMessageClass:true})
    }

  }

  tick() {
    var min = Math.floor(this.secondsRemaining / 60);
    var sec = this.secondsRemaining - (min * 60);

    this.setState({
      value: min,
      seconds: sec,
    })

    if (sec < 10) {
      this.setState({
        seconds: "0" + this.state.seconds,
      })

     }

    if (min < 10) {
      this.setState({
        value: "0" + min,
      })

    }

    if (min === 0 & sec === 0) {
      clearInterval(this.intervalHandle);
      this.setState({resendButtonEnable:'true'})
    }


    this.secondsRemaining--
  }

  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.state.value;
    this.secondsRemaining = time * 60;
    // this.setState({
    //   isClicked : true
    // })
  }

  render() {
    return (
      <div>
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="./images/logo.png"  alt="Logo"/>
          </div>
          <div className="authForm">

              <div className="otpScreen">

                <h5><a href="/signup" ><img src="./images/arrow_backward.png"  alt="arrow"/></a><span>OTP Verification</span></h5>
                <h3>Please Enter <br/> OTP Verification</h3>

                

                <p className="otpText">Code sent to mail {localStorage.getItem('user_email').substring(0, 8)} *** <br/> This code will expired in <span className="otpTime">{this.state.value}:{this.state.seconds}</span></p>
                
                <p>  
                  { this.state.messageerr1 ?
                    <span className={this.state.errorMessageClass ? "invalid-feedback" : "validFeedbackMessage"}>
                      {this.state.messageerr1}
                    </span>
                    : <span className={this.state.errorMessageClass ? "invalid-feedback" : "validFeedbackMessage"}>
                      {this.state.messageerr} </span>
                  }
                  </p>
                <div className="otpFields">
                  <input type="text" name="otp1" value={this.state.otp1}
                  onChange={this.handleChange.bind(this)} 
                  maxLength="1" ref='otp1' onFocus={() => this.setState({ oneFocus: true })} onBlur={() => this.setState({ oneFocus: false })} />
                   { this.state.err.otp1 ?
                    <span className="invalid-feedback">
                      {this.state.err.otp1}
                    </span>
                    : ''
                  }
                  <input type="text" name="otp2"  value={this.state.otp2}
                  onChange={this.handleChange.bind(this)} 
                  maxLength="1" ref='otp2' onFocus={() => this.setState({ twoFocus: true })} onBlur={() => this.setState({ twoFocus: false })}/>
                   { this.state.err.otp2 ?
                    <span className="invalid-feedback">
                      {this.state.err.otp2}
                    </span>
                    : ''
                  }
                  <input type="text" name="otp3" value={this.state.otp3}
                  onChange={this.handleChange.bind(this)} 
                 maxLength="1" ref='otp3' onFocus={() => this.setState({ twoFocus: true })} onBlur={() => this.setState({ twoFocus: false })}/>
                   { this.state.err.otp3 ?
                    <span className="invalid-feedback">
                      {this.state.err.otp3}
                    </span>
                    : ''
                  }
                  <input type="text" name="otp4" value={this.state.otp4}
                  onChange={this.handleChange.bind(this)} 
                   maxLength="1" ref='otp4' onFocus={() => this.setState({ twoFocus: true })} onBlur={() => this.setState({ twoFocus: false })}/>
                   { this.state.err.otp4 ?
                    <span className="invalid-feedback">
                      {this.state.err.otp4}
                    </span>
                    : ''
                  }
                </div>
                <div className="resendOtp">
                   <button type="submit"   className="btn btn-primary" disabled={!this.state.resendButtonEnable} onClick={this.resendOtp}>Resend OTP</button>
                </div>              
              </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

