import { connect } from 'react-redux'
import LoginComponent from './component'
import {  loginUser, userClearPhase, codeClearPhase } from '../../store/user/duck'

const LoginContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    loginPhase: state.user.loginPhase
  }),
  {
    loginUser,
    userClearPhase,
    codeClearPhase
  }
  // Map actions to dispatch and props
)(LoginComponent)

export default LoginContainer
