import { connect } from 'react-redux'
import { getAllServices, uploadImage, getServicesbyID} from '../../../store/service/duck'
import {  UpdateRequests, getRequestbyId } from '../../../store/request/duck'
import { fetchAllCenters } from '../../../store/center/duck'
import {userSetting} from '../../../store/user/duck'
import CollectionType from './component'

const CollectionTypeContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    uploadImage,
    getServicesbyID,
    UpdateRequests,
    getRequestbyId,
    fetchAllCenters,
    userSetting
  }
)(CollectionType)

export default CollectionTypeContainer