import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { message } from 'antd'
import "./styles.scss";
import { Link } from "react-router-dom";

export default class SignupComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      user_name: '',
      user_email: '',
      std_code: '',
      user_contact: '',
      err: {},
      message: '',
      disable: true,
      messageerr: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.signupUser = this.signupUser.bind(this)
  }

  handleChange (e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.user_email === '' || self.state.user_email === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  componentDidMount() {
    document.title = 'SignUp | DBC'

    if(localStorage.getItem('user_email') !== '' && localStorage.getItem('user_email') !== null && localStorage.getItem('user_email') !== undefined){
      this.setState({user_email:localStorage.getItem('user_email')})
    }
    if(localStorage.getItem('user_name')  !== '' && localStorage.getItem('user_email') !== null && localStorage.getItem('user_name')  !== undefined){
      this.setState({user_name:localStorage.getItem('user_name')})
    }
    if(localStorage.getItem('std_code')  !== '' && localStorage.getItem('user_email') !== null && localStorage.getItem('std_code')  !== undefined){
      this.setState({std_code:localStorage.getItem('std_code')})
    }
    if(localStorage.getItem('user_contact')  !== '' && localStorage.getItem('user_email') !== null && localStorage.getItem('user_contact')  !== undefined){
      this.setState({user_contact:localStorage.getItem('user_contact')})
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.signupPhase === 'success') {
      if (this.props.user) {
        if (this.props.user.status === 200) {

          //set token
          //localStorage.setItem('token', this.props.user.data.token)

          //used this email for otp verification
          localStorage.setItem('user_email', this.props.user.data.user_email)
          //used this detail for if user back from otp verify screen
          localStorage.setItem('user_name', this.state.user_name)
          //used this detail for if user back from otp verify screen
          localStorage.setItem('user_contact', this.state.user_contact)
          //used this detail for if user back from otp verify screen
          localStorage.setItem('std_code', this.state.std_code)

          //console.log(this.props.user);
          this.props.history.push('/otp')
        }else{
          //console.log(this.props.user.msg)
          this.setState({messageerr:this.props.user.msg})
        }
      }
    }
  }


  signupUser () {
    const { user_name, user_email, user_contact, std_code } = this.state

    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const err = {}

    if (user_name === '' || user_name === null || user_name.trim() === '') {
      err.user_name = 'Name is required.'
    }


    if (user_email === '' || user_email === null || user_email.trim() === '') {
      err.user_email = 'Email is required.'
    }


    if (user_email && user_email !== '') {
      const testR = emailRegex.test(String(this.state.user_email).toLowerCase())
      if (!testR) {
        err.user_email = 'Email is invalid.'
      }
    }

    if (user_contact && user_contact !== '') {
        const contactRegex = /^[0-9\b]+$/

        const testR1 = contactRegex.test(this.state.user_contact)
        if (!testR1) {
          err.user_contact = 'Contact number must be integer.'
        }else{
          if(std_code=='')
          {
            err.user_contact = 'Select code'
          }
        }
    }

    this.setState({ err })
    if (!Object.keys(err).length) {
      
      if (user_email) {
        const signupData = {
          user_email: this.state.user_email,
          user_name: this.state.user_name,
          user_contact: this.state.std_code+this.state.user_contact
        }
        this.props.signupUser(signupData)
      } else {
        message.error('Please insert valid input')
      }
    }
  }

  render() {

    return (
      <div>
        <div className="authScreeens">
        <div className="authScreeensInner">
          <div className="asLogo">
            <img src="./images/logo.png"/>
          </div>
          <div className="authForm">
           
              <h4>Sign up</h4>

               { this.state.messageerr ?
                    <span className="invalid-feedback">
                      {this.state.messageerr}
                    </span>
                    : ''
                  }

              <p className="authSubheading">Hello there, sign up to register!</p>
              <div className="form-group">
                <p className="formLabel">Full Name</p>
                <input type="text" placeholder="Enter your full name" className="form-control" name="user_name"
                    value={this.state.user_name}
                    onChange={this.handleChange.bind(this)} 
                    required/>
                     { this.state.err.user_name ?
                      <span className="invalid-feedback">
                        {this.state.err.user_name}
                      </span>
                      : ''
                    }
              </div>
              <div className="form-group">
                <p className="formLabel">Email</p>
                <input type="email" placeholder="Enter your email address" className="form-control" name="user_email"
                    value={this.state.user_email}
                    onChange={this.handleChange.bind(this)} 
                    required/>
                     { this.state.err.user_email ?
                      <span className="invalid-feedback">
                        {this.state.err.user_email}
                      </span>
                      : ''
                    }
              </div>
              <div className="form-group">
                <p className="formLabel">Mobile Number</p>
                <div className="d-flex">
                <div className="phoneDropDown">
                  <select className="form-control mdu-drop" value={this.state.std_code} name="std_code" onClick={this.handleChange.bind(this)} onChange={this.handleChange.bind(this)} >
                    <option value="971">+971</option>
                    <option value="972">+972</option>
                    <option value="973">+973</option>
                    <option value="974">+974</option>
                    <option value="975">+975</option>
                  </select>
                </div>
                <input type="text" placeholder="Enter your contact number" className="form-control" name="user_contact"
                    value={this.state.user_contact}
                    onChange={this.handleChange.bind(this)} 
                    required maxLength="10"/>
                     
              </div>
              { this.state.err.user_contact ?
                      <span className="invalid-feedback">
                        {this.state.err.user_contact}
                      </span>
                      : ''
                    }

              </div>
             
              <button type="submit" className="btn btn-primary" onClick={this.signupUser} onKeyDown={this.signupUser}>Next</button>
              <div className="formFooter">
              <p>Already have an account? <Link to={'/signin'}>Sign In</Link></p>
              </div>
            
          </div>
        </div>
        </div>
      </div>
    );
  }
}

