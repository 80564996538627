import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Spin } from 'antd';

class FeesSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      service_databyId: [],
      err: {},
      message: "",
      selectedItem: [],
      loading:false,
      service_name: ''
    };
  }

  async componentDidMount() {
    const service_id = window.localStorage.getItem('service_id')
    if(!service_id){
      this.props.history.push('/home');
    }
    
    this.setState({loading:true });
    const service_type = window.localStorage.getItem('service_type')
    let dataobj ={
      service_id : service_id
    }
    const datas = await this.props.getServicesbyID(dataobj)
    if (datas.value.status === 200) {
      // console.log(datas.value.data);
      this.setState({service_name: datas.value.data.service_name})
      var feeData = ''
      if(service_type=== "is_new"){
        feeData= JSON.parse(datas.value.data.feestructure_new);
      }else if(service_type=== "is_renewal"){
        feeData = JSON.parse(datas.value.data.feestructure_renewal);
      }else if(service_type=== "is_modify"){
        feeData = JSON.parse(datas.value.data.feestructure_modify);
      }else if(service_type=== "is_cancellation"){
        feeData = JSON.parse(datas.value.data.feestructure_cancellation);
      }
      this.setState({ service_databyId: feeData, loading:false });
    }
  }
render() {
  const { service_databyId,service_name } = this.state
  const userRole = window.localStorage.getItem('role')

  let service_data= ''
  if(userRole == 2){
   service_data = service_databyId.filter(it => new RegExp(true, "i").test(it.affiliate));
  }else{
   service_data = service_databyId.filter(it => new RegExp(true, "i").test(it.base));
  }
  // console.log(service_data)

  const totalprcie = service_data.reduce((totalCalories, meal) => totalCalories + parseInt(meal.price, 10), 0)
  return (<Spin spinning={this.state.loading} delay={500}>
          <div>
          <div className="addRequestInner">
          <div className="addReqContainer">
            <h5 className="heading_5">Processing Fees:</h5>
            <div className="tableTopData">
              <h6>{service_name}</h6>
              <p><span>Address</span> :  HSR Layout Dubai City</p>
            </div>
            <table className="table table-dashed">
              <tbody>
              {service_data.map((item, i) => {
                    return(
                    <tr key={item._id}>
                      <th>{item.label}</th>
                      <td>{item.price}</td>
                    </tr>
                  )
                  
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total Payment</th>
                  <td>{totalprcie} AED</td>
                </tr>
              </tfoot>
            </table>
          </div>
            
          </div>
          <div className="addReqSubmit">
           <Link to="/address" className="btn btn-primary">Agree & Next</Link>
          
        </div>
        </div></Spin>
  )};
};

export default FeesSummary;
