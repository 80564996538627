import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Spin, message } from "antd";

export default class Profile extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      requestData: [],
      user_name: '',
      password: '',
      user_contact: '',
      loading:false
    }
  }

  componentDidMount() {
    document.title = 'Profile | DBC'
    this.getUserAllDetails();
  }

  async getUserAllDetails() {
    this.setState({loading:true})
    const getData = await this.props.getUserDetails();
    if (getData.value.status === 200) {
      // console.log(getData.value.data, 'ppp');
      const data = getData.value.data
      this.setState({ user_name: data.user_name, user_contact: data.user_contact, password: data.password, loading:false })
    }else if (getData.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
  }

  handleChange(e) {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.user_name === '' || self.state.user_name === undefined) {
        disable = true
      }
      if (self.state.password === '' || self.state.password === undefined) {
        disable = true
      }
      if (self.state.user_contact === '' || self.state.user_contact === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({loading:true})
    const { user_name, password, user_contact } = this.state
    var is_submit = true
    const err = {}

    if (user_name === '' || user_name === null || user_name.trim() === '') {
      err.user_name = 'Name is required.'
      is_submit = false
    }


    if (password === '' || password === null || password.trim() === '') {
      err.password = 'Password is required.'
      is_submit = false
    }

    if (password.length<6) {
      err.password = 'Password length 6 is required.'
      is_submit = false
    }

    if (user_contact === '' || user_contact === null) {
      err.user_contact = 'Mobile number is required.'
      is_submit = false
    }


    if (user_contact && user_contact !== '') {
      const contactRegex = /^[0-9\b]+$/

      const testR1 = contactRegex.test(this.state.user_contact)
      if (!testR1) {
        err.user_contact = 'Mobile number must be integer.'
      }
    }

    this.setState({ err })
    if (!Object.keys(err).length) {

      if (is_submit===true && user_name && password && user_contact) {
        const Data = {
          user_name: this.state.user_name,
          password: this.state.password,
          user_contact: this.state.user_contact,
        }
        // console.log(Data, 'data')

        const resData = await this.props.updateUserDetails(Data);

        if (resData.value.status === 200) {
          // successfully
          this.getUserAllDetails();
          message.success("Updated Successfully");
          this.setState({loading:false})
        } else if (resData.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/signin');
        } else {
          // some error in server
          this.setState({loading:false})
          message.error("Some error occured please try again");
        }

      } else {
        this.setState({loading:false})
        message.error('Please insert valid input')
      }
    }else{
        this.setState({loading:false})
        // message.error('Please insert valid input');
    }
  }

  render() {
    const { loading } = this.state
    return (
      <div>
        <div className="pageWithMenu">
          <div className="profilePages">
            <h1 className="profileHeading">Profile</h1>
            <div className="profile-container">
            <Spin spinning={loading}>
              <form>
                <div className="form-group">
                  <input type="text" placeholder="Name" className="form-control" name="user_name"
                    value={this.state.user_name}
                    onChange={this.handleChange.bind(this)}
                    required />
                  {this.state.err.user_name ?
                    <span className="invalid-feedback">
                      {this.state.err.user_name}
                    </span>
                    : ''
                  }
                </div>
                <div className="form-group">
                  <input type="password" placeholder="Password" className="form-control" name="password"
                    value={this.state.password}
                    onChange={this.handleChange.bind(this)}
                    required />
                  {this.state.err.password ?
                    <span className="invalid-feedback">
                      {this.state.err.password}
                    </span>
                    : ''
                  }
                </div>
                <div className="form-group">
                  <input type="number" placeholder="Mobile Number" className="form-control" name="user_contact"
                    value={this.state.user_contact}
                    onChange={this.handleChange.bind(this)}
                    required maxLength="10" 
                    onInput={(e) => {
                              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 15)
                            }}/>
                  {this.state.err.user_contact ?
                    <span className="invalid-feedback">
                      {this.state.err.user_contact}
                    </span>
                    : ''
                  }
                </div>
                <div>
                  <div className="profileBottomButton">
                    <input type="submit" onClick={this.handleSubmit} value="Update" className="btn btn-primary" />
                  </div>
                </div>
              </form>
              </Spin>
            </div>

          </div>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><Link to="/"><img src="./images/icons/menu-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-active-3.png" alt="menu" /></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu" /></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-4.png" alt="menu" /></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
