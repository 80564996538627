import React, { PureComponent } from "react";
import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { step1Schema } from "../../../utils/validations";
import "./styles.scss";
import "../styles.scss";
import "antd/dist/antd.css";
import _ from "lodash";
import { Input, Select, Spin, Radio, message } from "antd";
// import Header from '../../Header/component'
const RadioGroup = Radio.Group;
const { Option } = Select;

// const AddRequest = () => {
class NewRequest extends PureComponent {
  // options = ['Test',"test"]
  constructor(props) {
    super(props);
    this.state = {
      all_service_data: [],
      service_databyId: [],
      most_popular_service_data: [],
      err: {},
      message: "",
      disable: true,
      image_path: "https://dbcapi.webvilleedemo.xyz/",
      service_id: "",
      selectedItem: [],
      service_type: "",
      service_name: "",
      loading: false,
      formfields: [],
      newInitialValues: {},
      renewalInitialValues: {},
      service_databyType: []
    };
  }

  handleChange = async (selectedItem, array) => {
    // console.log(`selected ${selectedItem}`);
    this.setState({ selectedItem });
    this.setState({ service_id: selectedItem });
    let data = {
      service_id: selectedItem
    };
    const datas = await this.props.getServicesbyID(data);
    this.setInitialValues(_.get(datas, `value.data.${array}`, []));
    if (datas.value.status === 200) {
      localStorage.removeItem("agreeCheck");
      this.setState({
        service_databyId: datas.value.data,
        // service_databyType: datas.value.data,
        service_name: datas.value.data.service_name,
        // service_type: ""
      });
    }else if (datas.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
  };

  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  async componentDidMount() {
    this.setState({ loading: true });
    const service_id = this.props.match.params.id
    await this.setAsyncState({ service_id: service_id })

    if (service_id) {
      let data = {
        service_id: service_id
      };
      const datas = await this.props.getServicesbyID(data);
      if (datas.value.status === 200) {
        await this.setAsyncState({
          service_databyId: datas.value.data,
          service_databyType: datas.value.data,
          service_name: datas.value.data.service_name,
          loading: false
        });
      }else if (datas.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }else{
        this.setState({ loading: false });
      }
    }

    const datas = await this.props.getAllServices();
    if (datas.value.status === 200) {
      await this.setAsyncState({ all_service_data: datas.value.data, loading: false });
    }
    const request_id = window.localStorage.getItem('request_id')
    const service_type = window.localStorage.getItem('service_type')
    if (request_id) {
      this.setState({ loading: true });
      const reqobj = {
        service_req_id: request_id
      }
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {

        let datareq = datar.value.data
        await this.setAsyncState({
          service_type: service_type,
          // service_databyId: datareq.service_id,
          service_name: datareq.service_name
        });
        this.setInitialValues(datareq.dynamic_field);
        if (service_type === "is_new") {
          this.setState({ service_databyId: { ...this.state.service_databyId, formfields_new: datareq.dynamic_field } })
        } else if (service_type === "is_renewal") {
          this.setState({ service_databyId: { ...this.state.service_databyId, formfields_renewal: datareq.dynamic_field } })
        } else if (service_type === "is_modify") {
          this.setState({ service_databyId: { ...this.state.service_databyId, formfields_modify: datareq.dynamic_field } })
        } else if (service_type === "is_cancellation") {
          this.setState({ service_databyId: { ...this.state.service_databyId, formfields_cancellation: datareq.dynamic_field } })
        }
        this.setState({ loading: false });
        // this.setState({ all_service_data: datar.value.data });
      }else if (datar.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }else{
        this.setState({ loading: false });
      }
    }
  }

  onChangeType = e => {
    const id = e.target.id
    this.setState(
      {
        service_type: e.target.value
      },
      () => {
        this.handleChange(this.state.service_id, id);
      }
    );
  };

  setInitialValues = data => {
    const newInitialValues = {};
    data.map(a => {
      newInitialValues[a.label] = a.value || "";
    });
    this.setState({ newInitialValues });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // this.setState({ loading: true });

    // this.setState({ success: true });
    // setTimeout(() => {
    //     this.setState({ success: false });
    // }, 3000);
  };

  onChangeNewArray(e, i, array) {
    const service_databyId = { ...this.state.service_databyId };
    service_databyId[array][i].value = e.target.value;
    this.setState({ service_databyId }, () => {
      this.setInitialValues(service_databyId[array]);
    });
  }

  onChangeNewdropdown(value, i, array) {
    const service_databyId = { ...this.state.service_databyId };
    service_databyId[array][i].value = value;
    this.setState({ service_databyId }, () => {
      this.setInitialValues(service_databyId[array]);
    });
  }

  handleSubmitForm = async () => {
    const { service_id, service_type, service_name, service_databyId } = this.state;
    var dynamic_field = ''
    if (service_type === "is_new") {
      dynamic_field = service_databyId.formfields_new
    } else if (service_type === "is_renewal") {
      dynamic_field = service_databyId.formfields_renewal
    } else if (service_type === "is_modify") {
      dynamic_field = service_databyId.formfields_modify
    } else if (service_type === "is_cancellation") {
      dynamic_field = service_databyId.formfields_cancellation
    }


    localStorage.setItem('service_type', service_type)
    localStorage.setItem('service_id', service_id)
    const userRole = window.localStorage.getItem('role')
    if (userRole == 4) {
      this.props.history.push('/required-documents');
    } else {
      const request_id = window.localStorage.getItem('request_id');
      if (request_id) {
        const requestData = {
          service_req_id: request_id,
          service_id: service_id,
          service_name: service_name,
          service_type: service_type,
          dynamic_field: dynamic_field
        };
        const datasuccess = await this.props.UpdateRequests(requestData);
        if (datasuccess.value.status === 200) {
          setTimeout(() => {
            this.props.history.push('/required-documents');
          }, 1000);
        }else if (datasuccess.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/signin');
        } else {
          message.error(datasuccess.value.msg);
        }
      } else {
        if (service_id && service_type && service_name && dynamic_field) {
          const requestData = {
            service_id: service_id,
            service_name: service_name,
            service_type: service_type,
            dynamic_field: dynamic_field
          };
          const datasuccess = await this.props.addRequests(requestData);

          if (datasuccess.value.status === 200) {
            // console.log(datasuccess.value.data)
            localStorage.setItem('request_id', datasuccess.value.data._id);
            // message.success(datasuccess.value.msg);
            setTimeout(() => {
              this.props.history.push('/required-documents');
              // this.setState({ loading: false, visible: false });
            }, 1000);
          } else if (datasuccess.value.status === 401) {
            localStorage.clear();
            this.props.history.push('/signin');
          } else {
            message.error(datasuccess.value.msg);
          }
        } else {
          message.error("Please insert all Fields");
        }


      }

    }
  }

  render() {
    const { all_service_data, service_databyType, service_databyId, selectedItem, service_type, service_name, err } = this.state;
    const { TextArea } = Input;
    return (
      <>
        <Spin spinning={this.state.loading} delay={500}>
          <div className="addRequestInner newRequest">
            <div className="addReqContainer">
              <Select
                name="service_name"
                value={service_name ? service_name : "Choose Service"}
                onChange={this.handleChange}
                className="shadowSelect serviceSelect"
                placeholder="Choose Service"
              >
                {all_service_data.map(item => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {item.service_name}
                    </Option>
                  );
                })}
              </Select>
              {err.service_name ? <span className="invalid-feedback">{err.service_name}</span> : ""}
              {service_name ? <label>Select Below Type</label> : ""}
            </div>
            <div className="subCatrgoryList" name="service_type" onChange={this.onChangeType}>
              <div
                className="sc_item"
                style={service_databyId.is_new === true ? { display: "block" } : { display: "none" }}
              >
                <Input type="radio" id="formfields_new" value="is_new" name="subCategory" checked={(service_type === "is_new") ? true : false} />
                <div className="scItemContent">New</div>
              </div>
              <div
                className="sc_item"
                style={service_databyId.is_renewal === true ? { display: "block" } : { display: "none" }}
              >
                <Input type="radio" id="formfields_renewal" value="is_renewal" name="subCategory" checked={(service_type === "is_renewal") ? true : false} />
                <div className="scItemContent">Renewal</div>
              </div>
              <div
                className="sc_item"
                style={service_databyId.is_modify === true ? { display: "block" } : { display: "none" }}
              >
                <Input type="radio" id="formfields_modify" value="is_modify" name="subCategory" />
                <div className="scItemContent" checked={(service_type === "is_modify") ? true : false}>Modify</div>
              </div>
              <div
                className="sc_item"
                style={service_databyId.is_cancellation === true ? { display: "block" } : { display: "none" }}
              >
                <Input type="radio" id="formfields_cancellation" value="is_cancellation" name="subCategory" checked={(service_type === "is_cancellation") ? true : false} />
                <div className="scItemContent">Cancellation</div>
              </div>
              {err.service_type ? <span className="invalid-feedback">{err.service_type}</span> : ""}
            </div>

            <div className="addReqContainer">
              {service_type === "is_new" && (
                <Formik
                  enableReinitialize
                  initialValues={this.state.newInitialValues}
                  validationSchema={step1Schema(_.get(service_databyId, "formfields_new", []))}
                  onSubmit={(values, formikProps) => this.handleSubmitForm(values, formikProps)}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <div>
                      <div className="formHeadingWithInfo">
                        <h5>
                          Applicant Details <img src="../images/icons/info.png" alt="info" />
                        </h5>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="applicantDetail box-shadow">
                          {service_databyId.formfields_new.map((item, i) => {
                            if (item.form_type === "text") {
                              return (
                                <span key={item._id}>
                                  <Input
                                    required
                                    type={item.form_type}
                                    name={item.label}
                                    placeholder={item.label}
                                    value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_new")}
                                  />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "dropdown") {
                              return (
                                <React.Fragment key={item._id}>
                                  <label>{item.label}</label>
                                  <Select
                                    name={item.label}
                                    value={item.value || item.label}
                                    onChange={e => this.onChangeNewdropdown(e, i, "formfields_new")}
                                    className="shadowSelect serviceSelect"
                                  >
                                    <Option value="">Choose {item.label}</Option>
                                    {item.options.map((item2, i2) => {
                                      return (
                                        <Option key={i2} value={item2.value}>
                                          {item2.label}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </React.Fragment>
                              );
                            } else if (item.form_type === "checkbox") {
                              return (
                                <div className="form-group" key={item._id}>
                                  <p className="fromLabel">{item.label}</p>
                                  <RadioGroup
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_new")}
                                    value={item.value}
                                  >
                                    {item.options.map((item1, i2) => {
                                      return (
                                        <Radio key={i2} value={item1.value}>
                                          {item1.label}
                                        </Radio>
                                      );
                                    })}
                                  </RadioGroup>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </div>
                              );
                            } else if (item.form_type === "number") {
                              return (
                                <span key={item._id} >
                                  <Input value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_new")} type={item.form_type} name={item.label}
                                    placeholder={item.label} />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "textarea") {
                              return (
                                <div className="applicantRequestDes" key={item._id} value={item._id}>
                                  <div className="formHeadingWithInfo">
                                    <h5>{item.label}<img src="../images/icons/info.png" alt="info" /></h5>
                                  </div>
                                  <TextArea type={item.form_type} placeholder={item.label} value={(item.value) ? item.value : ''} onChange={e => this.onChangeNewArray(e, i, "formfields_new")}></TextArea>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </div>)
                            }
                          })}
                        </div>
                        <div className="addReqSubmit">
                          <Input type="submit" className="btn btn-primary" value="Next" />
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              )}

              {service_type === "is_renewal" && (
                <Formik
                  enableReinitialize
                  initialValues={this.state.newInitialValues}
                  validationSchema={step1Schema(_.get(service_databyId, "formfields_renewal", []))}
                  onSubmit={(values, formikProps) => this.handleSubmitForm(values, formikProps)}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <div>
                      <div className="formHeadingWithInfo">
                        <h5>
                          Applicant Details <img src="../images/icons/info.png" alt="info" />
                        </h5>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="applicantDetail box-shadow">
                          {service_databyId.formfields_renewal.map((item, i) => {
                            if (item.form_type === "text") {
                              return (
                                <span key={item._id}>
                                  <Input
                                    required
                                    type={item.form_type}
                                    name={item.label}
                                    placeholder={item.label}
                                    value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_renewal")}
                                  />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "dropdown") {
                              return (
                                <React.Fragment key={item._id}>
                                  <label>{item.label}</label>
                                  <Select
                                    name={item.label}
                                    value={item.value || item.label}
                                    onChange={e => this.onChangeNewdropdown(e, i, "formfields_renewal")}
                                    className="shadowSelect serviceSelect"
                                  >
                                    <Option value="">Choose {item.label}</Option>
                                    {item.options.map((item2, i2) => {
                                      return (
                                        <Option key={i2} value={item2.value}>
                                          {item2.label}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </React.Fragment>
                              );
                            } else if (item.form_type === "checkbox") {
                              return (
                                <span key={item._id}>
                                  <label>{item.label}</label>
                                  <RadioGroup
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_renewal")}
                                    value={item.value}
                                  >
                                    {item.options.map((item1, i2) => {
                                      return (
                                        <Radio key={i2} value={item1.value}>
                                          {item1.label}
                                        </Radio>
                                      );
                                    })}
                                  </RadioGroup>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "number") {
                              return (
                                <span key={item._id} >
                                  <Input value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_renewal")} type={item.form_type} name={item.label}
                                    placeholder={item.label} />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "textarea") {
                              return (
                                <div className="applicantRequestDes" key={item._id} value={item._id}>
                                  <div className="formHeadingWithInfo">
                                    <h5>{item.label}<img src="../images/icons/info.png" alt="info" /></h5>
                                  </div>
                                  <TextArea type={item.form_type} placeholder={item.label} value={(item.value) ? item.value : ''} onChange={e => this.onChangeNewArray(e, i, "formfields_renewal")}></TextArea>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </div>)
                            }
                          })}
                        </div>
                        <div className="addReqSubmit">
                          <Input type="submit" className="btn btn-primary" value="Next" />
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              )}

              {service_type === "is_modify" && (
                <Formik
                  enableReinitialize
                  initialValues={this.state.newInitialValues}
                  validationSchema={step1Schema(_.get(service_databyId, "formfields_modify", []))}
                  onSubmit={(values, formikProps) => this.handleSubmitForm(values, formikProps)}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <div>
                      <div className="formHeadingWithInfo">
                        <h5>
                          Applicant Details <img src="../images/icons/info.png" alt="info" />
                        </h5>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="applicantDetail box-shadow">
                          {service_databyId.formfields_modify.map((item, i) => {
                            if (item.form_type === "text") {
                              return (
                                <span key={item._id}>
                                  <Input
                                    required
                                    type={item.form_type}
                                    name={item.label}
                                    placeholder={item.label}
                                    value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_modify")}
                                  />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "dropdown") {
                              return (
                                <React.Fragment key={item._id}>
                                  <label>{item.label}</label>
                                  <Select
                                    name={item.label}
                                    value={item.value || item.label}
                                    onChange={e => this.onChangeNewdropdown(e, i, "formfields_modify")}
                                    className="shadowSelect serviceSelect"
                                  >
                                    <Option value="">Choose {item.label}</Option>
                                    {item.options.map((item2, i2) => {
                                      return (
                                        <Option key={i2} value={item2.value}>
                                          {item2.label}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </React.Fragment>
                              );
                            } else if (item.form_type === "checkbox") {
                              return (
                                <span key={item._id}>
                                  <label>{item.label}</label>
                                  <RadioGroup
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_modify")}
                                    value={item.value}
                                  >
                                    {item.options.map((item1, i2) => {
                                      return (
                                        <Radio key={i2} value={item1.value}>
                                          {item1.label}
                                        </Radio>
                                      );
                                    })}
                                  </RadioGroup>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "number") {
                              return (
                                <span key={item._id}>
                                  <Input value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_modify")} type={item.form_type} name={item.label}
                                    placeholder={item.label} />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "textarea") {
                              return (
                                <div className="applicantRequestDes" key={item._id} value={item._id}>
                                  <div className="formHeadingWithInfo">
                                    <h5>{item.label}<img src="../images/icons/info.png" alt="info" /></h5>
                                  </div>
                                  <TextArea type={item.form_type} placeholder={item.label} value={(item.value) ? item.value : ''} onChange={e => this.onChangeNewArray(e, i, "formfields_modify")}></TextArea>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </div>)
                            }
                          })}
                        </div>
                        <div className="addReqSubmit">
                          <Input type="submit" className="btn btn-primary" value="Next" />
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              )}

              {service_type === "is_cancellation" && (
                <Formik
                  enableReinitialize
                  initialValues={this.state.newInitialValues}
                  validationSchema={step1Schema(_.get(service_databyId, "formfields_cancellation", []))}
                  onSubmit={(values, formikProps) => this.handleSubmitForm(values, formikProps)}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <div>
                      <div className="formHeadingWithInfo">
                        <h5>
                          Applicant Details <img src="../images/icons/info.png" alt="info" />
                        </h5>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="applicantDetail box-shadow">
                          {service_databyId.formfields_cancellation.map((item, i) => {
                            if (item.form_type === "text") {
                              return (
                                <span key={item._id}>
                                  <Input
                                    required
                                    type={item.form_type}
                                    name={item.label}
                                    placeholder={item.label}
                                    value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_cancellation")}
                                  />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "dropdown") {
                              return (
                                <React.Fragment key={item._id}>
                                  <label>{item.label}</label>
                                  <Select
                                    name={item.label}
                                    value={item.value || item.label}
                                    onChange={e => this.onChangeNewdropdown(e, i, "formfields_cancellation")}
                                    className="shadowSelect serviceSelect"
                                  >
                                    <Option value="">Choose {item.label}</Option>
                                    {item.options.map((item2, i2) => {
                                      return (
                                        <Option key={i2} value={item2.value}>
                                          {item2.label}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </React.Fragment>
                              );
                            } else if (item.form_type === "checkbox") {
                              return (
                                <span key={item._id}>
                                  <label>{item.label}</label>
                                  <RadioGroup
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_cancellation")}
                                    value={item.value}
                                  >
                                    {item.options.map((item1, i2) => {
                                      return (
                                        <Radio key={i2} value={item1.value}>
                                          {item1.label}
                                        </Radio>
                                      );
                                    })}
                                  </RadioGroup>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "number") {
                              return (
                                <span key={item._id}>
                                  <Input value={item.value}
                                    onChange={e => this.onChangeNewArray(e, i, "formfields_cancellation")} type={item.form_type} placeholder={item.label} />
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </span>
                              );
                            } else if (item.form_type === "textarea") {
                              return (
                                <div className="applicantRequestDes" key={item._id} value={item._id}>
                                  <div className="formHeadingWithInfo">
                                    <h5>{item.label}<img src="../images/icons/info.png" alt="info" /></h5>
                                  </div>
                                  <TextArea type={item.form_type} placeholder={item.label} value={(item.value) ? item.value : ''} onChange={e => this.onChangeNewArray(e, i, "formfields_cancellation")}></TextArea>
                                  <ErrorMessage
                                    component="span"
                                    name={item.label}
                                    className="invalid-feedback d-block"
                                  />
                                </div>)
                            }

                          })}
                        </div>
                        <div className="addReqSubmit">
                          <Input type="submit" className="btn btn-primary" value="Next" />
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              )}
            </div>
          </div>
          {/* <div className="addReqSubmit">
          {/* <Link to="required-documents" className="btn btn-primary">Next</Link>
          <Input type="submit" className="btn btn-primary" value="Next" />
        </div> */}
        </Spin>
      </>
    );
  }
}

export default NewRequest;