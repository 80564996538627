import React, { PureComponent } from 'react'
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Spin, Button, Modal } from 'antd';

export default class HomeComponent extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      all_service_data: [],
      most_popular_service_data: [],
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      image_path: 'https://dbcapi.webvilleedemo.xyz/',
      search_value:'',
      Hostname: 'https://dbcapi.webvilleedemo.xyz/uploads/',
      search_all_service_data: [],
      unread_messages:[],
      unread_msg_count:0,
      modal1Visible: false,
      uID:''
    }
    this.addRequest = this.addRequest.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }


   componentDidMount = () => {

    document.title = 'Home | DBC'
    const uID = window.localStorage.getItem('userID');
    this.setState({uID})
    // console.log(this.props.socket, 'socket')
    setInterval(()=>{
      this.props.socket.emit('unread_messages_count', uID);
      this.props.socket.on("unread_messages_count_ack",(data)=>{
        // console.log("unread_messages_count_ack :- ", data.data.count);
        this.setState({unread_msg_count: data.data.count})
        // this.getAllUnreadMessage();
      })
      
      }, 1000);

    this.getAllUnreadMessage();
    this.getAllServices();
    

    localStorage.removeItem("service_id");
    localStorage.removeItem("request_id");
    localStorage.removeItem("service_type");
    localStorage.removeItem("agreeCheck");
    localStorage.removeItem("request_type");
  }

  getAllUnreadMessage = async()=>{
    // console.log(this.state.uID, 'oo')
    const MessagesData = {
      user_id:window.localStorage.getItem('userID')
    }
    const datamsg = await this.props.getAllUnreadMessages(MessagesData);
    if (datamsg.value.status === 200) {
      // const allData = datamsg
      // console.log(datamsg, 'ookkkkkkkmsgs');
      this.setState({ unread_messages: datamsg.value.data });
    }
  }

  getAllServices= async()=> {
    this.setState({loading:true})
    const resData = await this.props.getAllServices()
    if(resData.value.status === 200){
      this.setState({most_popular_service_data:resData.value.data.slice(0, 4)})
      this.setState({all_service_data:resData.value.data, search_all_service_data:resData.value.data, loading:false})
    }
  }

  setModal1Visible(modal1Visible) {
    this.getAllUnreadMessage();
    this.setState({ modal1Visible });
  }

  addRequest(id) {
    // this.props.history.push(`/add-request`)
    this.props.history.push(`/add-request/${id}`)
  }

   handleChange (e) {
    this.setState({ [e.target.name]: e.target.value }, () => {

      if(this.state.search_value !== ''){
       //this.setState({ search_vale: this.props.search_value });     
        const newData = this.state.all_service_data.filter(item => {      
          const itemData = `${item.service_name.toUpperCase()}`;
          const textData = this.state.search_value.toUpperCase();  
          return itemData.indexOf(textData) > -1;    
        });
        
        this.setState({ search_all_service_data: newData });
      }else{
        this.setState({ search_all_service_data: this.state.all_service_data });
      }
    })
  }
  readMessage=(requestId, recieverId)=>{
    const messageData = {
      recieverId: recieverId,
      requestId: requestId,
    }
    // console.log(messageData, '8989898989')

    this.props.socket.emit('read_messages_ack', messageData);
    this.props.socket.on("recieve_message_ack", (data) => {
      // console.log("receive read message :- ", data);
    });
  }

  render() {
    //const { all_service_data } = this.state
    const {Hostname, most_popular_service_data, search_all_service_data, unread_msg_count, unread_messages} = this.state
    console.log(unread_msg_count, 'count==', unread_messages);
    return (
      <div><Spin spinning={this.state.loading} delay={500}>
        <div className="pageHome pageWithMenu">
          <div className="homeBanner">
            <div className="homeBannerInner">
              <div className="bannerHeading">
                <h1>Get your services <br/> done without  <br/> any hassle</h1>
                <Button onClick={() => this.setModal1Visible(true)}type="primary" shape="circle">
                  <img src="./images/icons/chat.png" alt="chat"/>
                  <span>{unread_msg_count}</span>
                </Button>
              
                <Modal
              // title="20px to Top"
              style={{ top: 20 }}
              title="Messages"
              visible={this.state.modal1Visible}
              // onOk={() => this.setModal1Visible(false)}
              onCancel={() => this.setModal1Visible(false)}
             className="messagesNotification">
            <ul className="notificationList">
            {unread_messages.length>0 && unread_messages.map((item, i) => {
              return(<li key={item._id}>
                {(item.type === 1) ? <Link onClick={()=>this.readMessage(item.requestId, item.recieverId)} to={`/chatboard/${item.requestId}`}>{item.message}</Link>
                                  : (item.type === 2) ? <Link onClick={()=>this.readMessage(item.requestId, item.recieverId)} to={`/chatboard/${item.requestId}`}><img style={{ width: 50, height: 50 }} alt="example" src={Hostname + item.message} /></Link> :
                                    (item.type === 3) ? <Link onClick={()=>this.readMessage(item.requestId, item.recieverId)} to={`/chatboard/${item.requestId}`}>Pdf File </Link> : ''
                                }
              
              </li>)
            })}
            </ul>
        
        </Modal>
              </div>
              <input type="search" name="search_value" value={this.state.search_value} placeholder="Search for services" onChange={this.handleChange.bind(this)}/>
            </div>
          </div>
          <div className="homeMain">
            <div className="homeMainInner">
              <div className="homeMostPopular">
                <h4>Most Popular Services</h4>
                <div className="homeMPList">
                  {most_popular_service_data.length>0 && most_popular_service_data.map((item, index) =>
                    <div className="hmpItem tooltip_service" key={ index }
                    onClick={() => this.addRequest(item._id)}
                    >
                      <img style={{width:'30px', height:'30px'}} src={`${this.state.image_path}/${item.service_icon}`} alt="logo"/>
                      <p>{item.service_name}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="homeCommonService">
                <h4>Common Services</h4>
                  <div className="hcs_row">
                    {search_all_service_data.length>0 && search_all_service_data.map((item, index) =>
                      <div className="hcs_col tooltip_service" key={ index } onClick={() => this.addRequest(item._id)}>
                       <div className="hcs_card">
                         <img style={{width:'30px', height:'30px'}} src={`${this.state.image_path}/${item.service_icon}`} alt="logo"/>
                         <h5>{item.service_name}</h5>
                         {/* <p>Age greater than 17</p> */}
                       </div>
                     </div>
                    )}
                  </div>
              </div>
            </div>
          </div>
         <div className="footerMenuWrap">
          <div className="menuMain">
            <ul> 
                <li><Link to="/"><img src="./images/icons/menu-active-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-3.png" alt="menu"/></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu"/></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-4.png" alt="menu"/></Link></li>
              </ul>
          </div>
        </div>
        </div></Spin>
      </div>
      );
    }
}

