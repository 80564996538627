import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://dbcapi.webvilleedemo.xyz'

export const fetchAllCenters = () => {
  return fetch(`${HOSTNAME}/web/get_center`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchCenters = (searchText) => {
  return fetch(`${HOSTNAME}/center?q=${searchText}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}