import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import {  Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Spin, Input, Form, message } from 'antd';
// const { Option } = Select;
// const RequiredDocuments = () => {
  class RequiredDocuments extends PureComponent {

    constructor(props) {
      super(props);
      this.state = {
        service_docbyId: [],
        err: {},
        message: "",
        selectedItem: [],
        loading:false,
        agreeCheck:false
      };
    }

    async componentDidMount() {
      const service_id = window.localStorage.getItem('service_id')
      if(!service_id){
        this.props.history.push('/home');
      }
      
      // localStorage.getItem('agreeCheck', agreeCheck)
      const agreeCheck = window.localStorage.getItem('agreeCheck')
      if(agreeCheck){
        this.setState({agreeCheck:agreeCheck})
      }
      this.setState({loading:true });
      const service_type = window.localStorage.getItem('service_type')
      let dataobj ={
        service_id : service_id
      }
      const datas = await this.props.getServicesbyID(dataobj)
      if (datas.value.status === 200) {
        // console.log(datas.value.data);
        var feeData = ''
        if(service_type=== "is_new"){
          feeData= datas.value.data.doclist_new;
        }else if(service_type=== "is_renewal"){
          feeData = datas.value.data.doclist_renewal;
        }else if(service_type=== "is_modify"){
          feeData = datas.value.data.doclist_modify;
        }else if(service_type=== "is_cancellation"){
          feeData = datas.value.data.doclist_cancellation;
        }
        this.setState({ service_docbyId: feeData, loading:false });
      }
    }
    
    handleChnage=(e)=>{
      this.setState({agreeCheck:e.target.checked})
      // console.log(e.target.checked, "iiii")
    }

  handleSubmit = async () => {
      const { agreeCheck } = this.state
      console.log(agreeCheck)
      if(agreeCheck){
        console.log(agreeCheck, 'klklk')
        localStorage.setItem('agreeCheck', agreeCheck)
        this.props.history.push('/fees-summary');
      }else{
        message.error("Please tick above box checked if you want to proceed")
      }
      // console.log("hjgjhgjh")
      // this.props.history.push('/fees-summary');
  }

  render() {
  const { service_docbyId } = this.state
    
  return (<Spin spinning={this.state.loading} delay={500}>
    <div>
    <Form>
    <div className="addRequestInner">
    <div className="addReqContainer">
            <h5 className="heading_5">Please get ready the below documents for your request</h5>
            <div className="reqDocList">
              <ul>
               {service_docbyId.map((item, i) => {
                    return(
                      <li key={item._id}>{item.label} (Note:- {item.doc_type})</li>
                    )
                })}
              </ul>
            </div>

            <div className="impNote">
              <h3><img src="./images/icons/speaker.png"  alt="left"/> <span>Important Note</span></h3>
              <p>Please acknowledge that the above listed documents should be provided during the scheduled document collection time.</p>
            </div>
            <div className="custom-control custom-checkbox reqDocCheckbox ">
              <input type="checkbox" name="agreeCheck" checked={this.state.agreeCheck} onChange={this.handleChnage} className="custom-control-input" id="customCheck" />
              <label className="custom-control-label" for="customCheck">I confirm that above listed documents will be provided to the collection agent.</label>
            </div>
        </div>
        </div>
          <div className="addReqSubmit">
            {/* <Link to="fees-summary" className="btn btn-primary">Next</Link> */}
            <Input type="submit" onClick={this.handleSubmit} className="btn btn-primary" value="Next" />
          </div></Form>
          </div></Spin> 
  )};
};

export default RequiredDocuments;
