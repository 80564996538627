import { connect } from 'react-redux'
import OtpComponent from './component'
import {  sendVerificationCode, userClearPhase, codeClearPhase, resendOtp } from '../../store/user/duck'

const OtpContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    sendVerificationCodePhase: state.user.sendVerificationCodePhase
    // resendOtpPhase: state.user.resendOtpPhase
  }),
  {
    sendVerificationCode,
    userClearPhase,
    codeClearPhase,
    resendOtp
  }
  // Map actions to dispatch and props
)(OtpComponent)

export default OtpContainer
