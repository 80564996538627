// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'


import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const LOGIN_USER = 'dbc/user/LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'dbc/user/LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'dbc/user/LOGIN_USER_ERROR'

export const SIGNUP_USER = 'dbc/user/SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'dbc/user/SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_ERROR = 'dbc/user/SIGNUP_USER_ERROR'

export const SEND_VERIFICATION_CODE = 'dbc/user/SEND_VERIFICATION_CODE'
export const SEND_VERIFICATION_CODE_SUCCESS = 'dbc/user/SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_ERROR = 'dbc/user/SEND_VERIFICATION_CODE_ERROR'

export const SIGNUP_DETAIL = 'dbc/user/SIGNUP_DETAIL'
export const SIGNUP_DETAIL_SUCCESS = 'dbc/user/SIGNUP_DETAIL_SUCCESS'
export const SIGNUP_DETAIL_ERROR = 'dbc/user/SIGNUP_DETAIL_ERROR'

export const FORGOT_PASSWORD = 'dbc/user/FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'dbc/user/FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'dbc/user/FORGOT_PASSWORD_ERROR'

export const RESEND_OTP = 'dbc/user/RESEND_OTP'
// export const RESEND_OTP_SUCCESS = 'dbc/user/RESEND_OTP_SUCCESS'
// export const RESEND_OTP_ERROR = 'dbc/user/RESEND_OTP_ERROR'

export const GET_USER_SETTING = 'dbc/user/GET_USER_SETTING'

export const CLEAR_PHASE = 'dbc/user/CLEAR_PHASE'
export const CLEAR_VERIFICATION_CODE = 'dbc/user/CLEAR_VERIFICATION_CODE'

export const LOGOUT_USER = 'dbc/user/LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'dbc/user/LOGOUT_USER_SUCCESS'
export const UPDATE_USER_DETAILS = 'dbc/user/UPDATE_USER_DETAILS'
export const GET_USER_DETAILS = 'dbc/user/GET_USER_DETAILS'
export const GET_CONTENT = 'dbc/user/GET_CONTENT'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  signupPhase: INIT,
  otpPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: null,
  signupDetailPhase: INIT,
  forgotPasswordPhase: INIT,
  resendOtpPhase: INIT
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SEND_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodePhase', LOADING)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_SUCCESS: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', SUCCESS)
        .set('user', payload)
        .set('message', payload.message)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_ERROR: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', ERROR)
        .set('message', payload.error.message)
        .set('error', null)
    }

    case LOGIN_USER: {
      return state
        .set('loginPhase', LOADING)
        .set('loginError', null)
    }

    case LOGIN_USER_SUCCESS: {
      const { payload } = action
      localStorage.setItem('token', payload.data.token)
      return state
        .set('loginPhase', SUCCESS)
        .set('user', payload)
        .set('loginError', null)
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action
      return state
        .set('loginError', payload.error)
        .set('loginPhase', ERROR)
    }

    case SIGNUP_USER: {
      return state
        .set('signupPhase', LOADING)
        .set('signupError', null)
    }

    case SIGNUP_USER_SUCCESS: {
      const { payload } = action
      // localStorage.setItem('token', payload.data.token)
      return state
        .set('signupPhase', SUCCESS)
        .set('user', payload)
        .set('signupError', null)
    }

    case SIGNUP_USER_ERROR: {
      const { payload } = action
      return state
        .set('signupError', payload.error)
        .set('signupPhase', ERROR)
    }

    case SIGNUP_DETAIL: {
      return state
        .set('signupDetailPhase', LOADING)
        .set('signupDetailError', null)
    }

    case SIGNUP_DETAIL_SUCCESS: {
      const { payload } = action
      return state
        .set('signupDetailPhase', SUCCESS)
        .set('user', payload)
        .set('signupDetailError', null)
    }

    case SIGNUP_DETAIL_ERROR: {
      const { payload } = action
      return state
        .set('signupDetailError', payload.error)
        .set('signupDetailPhase', ERROR)
    }

    case FORGOT_PASSWORD: {
      return state
        .set('forgotPasswordPhase', LOADING)
        .set('forgotPasswordError', null)
    }

    case FORGOT_PASSWORD_SUCCESS: {
      const { payload } = action
      // localStorage.setItem('token', payload.data.token)
      return state
        .set('forgotPasswordPhase', SUCCESS)
        .set('user', payload)
        .set('forgotPasswordError', null)
    }

    case FORGOT_PASSWORD_ERROR: {
      const { payload } = action
      return state
        .set('forgotPasswordError', payload.error)
        .set('forgotPasswordPhase', ERROR)
    }

    // case RESEND_OTP: {
    //   return state
    //     .set('resendOtpPhase', LOADING)
    //     .set('resendOtpError', null)
    // }

    // case RESEND_OTP_SUCCESS: {
    //   const { payload } = action
    //   // localStorage.setItem('token', payload.data.token)
    //   return state
    //     .set('resendOtpPhase', SUCCESS)
    //     .set('user', payload)
    //     .set('resendOtpError', null)
    // }

    // case RESEND_OTP_ERROR: {
    //   const { payload } = action
    //   return state
    //     .set('resendOtpError', payload.error)
    //     .set('resendOtpPhase', ERROR)
    // }

    case CLEAR_PHASE: {
      return state
        .set('sendVerificationCodePhase', INIT)
        .set('message', '')
    }

    case CLEAR_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodeData', INIT)
    }

    case LOGOUT_USER: {
      // cookies.remove('Authorization')
      localStorage.removeItem('Authorization') //remove only user
      localStorage.clear() // for remove Authorization token
      return state
        .set('phase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const sendVerificationCode = credentials => {
  return {
    type: SEND_VERIFICATION_CODE,
    payload: credentials
  }
}

export const loginUser = credentials => {
  return {
    type: LOGIN_USER,
    payload: credentials
  }
}

export const signupUser = credentials => {
  return {
    type: SIGNUP_USER,
    payload: credentials
  }
}

export const signupDetail = credentials => {
  return {
    type: SIGNUP_DETAIL,
    payload: credentials
  }
}

export const forgotPassword = credentials => {
  return {
    type: FORGOT_PASSWORD,
    payload: credentials
  }
}


export const resendOtp = payload => {
  return {
    type: RESEND_OTP,
    payload: api.resendOtp(payload)
  }
}


export const userSetting = payload => {
  return {
    type: GET_USER_SETTING,
    payload: api.userSetting()
  }
}

export const updateUserDetails = payload => {
  return {
    type: UPDATE_USER_DETAILS,
    payload: api.updateUserDetails(payload)
  }
}

export const getUserDetails = payload => {
  return {
    type: GET_USER_DETAILS,
    payload: api.getUserDetails(payload)
  }
}

export const getContent = payload => {
  return {
    type: GET_CONTENT,
    payload: api.getContent(payload)
  }
}

export const userClearPhase = credentials => {
  return {
    type: CLEAR_PHASE,
    payload: credentials
  }
}

export const codeClearPhase = credentials => {
  return {
    type: CLEAR_VERIFICATION_CODE,
    payload: credentials
  }
}

export const handleSignOut = () => ({
  type: LOGOUT_USER
})

/***********************************
 * Epics
 ***********************************/

const sendVerificationCodeEpic = action$ =>
  action$.pipe(
    ofType(SEND_VERIFICATION_CODE),
    mergeMap(action => {
      return fromPromise(api.sendVerificationCode(action.payload)).pipe(
        flatMap(payload => [
          {
            type: SEND_VERIFICATION_CODE_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: SEND_VERIFICATION_CODE_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const loginUserEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_USER),
    mergeMap(action => {
      return fromPromise(api.loginUser(action.payload)).pipe(
        flatMap(payload => [
          {
            type: LOGIN_USER_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: LOGIN_USER_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

  const signupUserEpic = action$ =>
  action$.pipe(
    ofType(SIGNUP_USER),
    mergeMap(action => {
      return fromPromise(api.signupUser(action.payload)).pipe(
        flatMap(payload => [
          {
            type: SIGNUP_USER_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: SIGNUP_USER_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

  const signupDetailEpic = action$ =>
  action$.pipe(
    ofType(SIGNUP_DETAIL),
    mergeMap(action => {
      return fromPromise(api.signupDetail(action.payload)).pipe(
        flatMap(payload => [
          {
            type: SIGNUP_DETAIL_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: SIGNUP_DETAIL_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const forgotPasswordEpic = action$ =>
  action$.pipe(
    ofType(FORGOT_PASSWORD),
    mergeMap(action => {
      return fromPromise(api.forgotPassword(action.payload)).pipe(
        flatMap(payload => [
          {
            type: FORGOT_PASSWORD_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: FORGOT_PASSWORD_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

  // const resendOtpEpic = action$ =>
  // action$.pipe(
  //   ofType(RESEND_OTP),
  //   mergeMap(action => {
  //     return fromPromise(api.resendOtp(action.payload)).pipe(
  //       flatMap(payload => [
  //         {
  //           type: RESEND_OTP_SUCCESS,
  //           payload
  //         }
  //       ]),
  //       catchError(error =>
  //         of({
  //           type: RESEND_OTP_ERROR,
  //           payload: { error }
  //         })
  //       )
  //     )
  //   })
  // )


export const userEpic = combineEpics(
  sendVerificationCodeEpic,
  loginUserEpic,
  signupUserEpic,
  signupDetailEpic,
  forgotPasswordEpic,
  // resendOtpEpic,
)