import { connect } from 'react-redux'
import TrackComponent from './component'
import { getTrackingData } from '../../store/request/duck'

const TrackContainer = connect(
  // Map state to props
  state => ({ }),
  {
    getTrackingData
  }
  // Map actions to dispatch and props
)(TrackComponent)

export default TrackContainer
