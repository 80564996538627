// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const UPLOAD_IMAGE = 'dbc/service/UPLOAD_IMAGE'
export const GET_ALL_SERVICES = 'dbc/service/GET_ALL_SERVICES'
export const GET_SERVICE_BY_ID = 'dbc/service/GET_SERVICE_BY_ID'
export const DOC_REMOVE = 'dbc/service/DOC_REMOVE'

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/
export const uploadImage = payload => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImage(payload)
  }
}

export const getAllServices = payload => {
  return {
    type: GET_ALL_SERVICES,
    payload: api.getAllServices(payload)
  }
}

export const getServicesbyID = payload => {
  return {
    type: GET_SERVICE_BY_ID,
    payload: api.getServicesbyID(payload)
  }
}

export const documentRemove = payload => {
  return {
    type: DOC_REMOVE,
    payload: api.documentRemove(payload)
  }
}


/***********************************
 * Epics
 ***********************************/

export const serviceEpic = combineEpics()