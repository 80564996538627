import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Select, DatePicker, Spin } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select;
const logout = () => {
  localStorage.clear();
};
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
export default class Transactions extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      err: {},
      message: '',
      disable: true,
      messageerr: '',
      requestData: [],
      fromDate:'',
      toDate:'',
      pageNo:0,
      loading: false
    }
  }


  async componentDidMount() {
    document.title = 'Transactions | DBC'
    this.getAllTransactionBYfilter();
    
  }

  getAllTransactionBYfilter = async()=>{
    this.setState({loading: true})
    const {fromDate, toDate, pageNo}= this.state
    const RequesTransaction = {
      from_date: fromDate,
      to_date: toDate,
      page_no: pageNo,
    }

    const datareq = await this.props.getTransactionByfilter(RequesTransaction);
    if (datareq.value.status === 200) {
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ requestData: datareq.value.data, loading: false });
    }else if(datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }else{
      this.setState({loading: false})
    }

  }

  onChanged = (value, dateString)=> {
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
    this.setState({fromDate:dateString[0],  toDate:dateString[1]+' 23:59:59'}, ()=>this.getAllTransactionBYfilter())
  }
  


  render() {
    const {requestData, fromDate, toDate} =this.state
    // console.log(requestData)
    return (
      <div>
        <div className="pageWithMenu">
          <div className="profilePages">
            <h1 className="profileHeading">Transactions</h1>
            <div className="profile-container">
              <div className="transMonthYear d-flex justify-content-between">
                <div className="transSelectedMY">
                  {/* <p>2020</p> */}
                  <h5><RangePicker showTime onChange={this.onChanged} className="transactionDate"/></h5>
                </div>
                {/* <div className="transSelect"> */}
                  {/* <Select defaultValue="1 Month" onChange={handleChange}>
                    <Option value="1 Month">1 Month</Option>
                    <Option value="2 Month">2 Month</Option>
                    <Option value="3 Month">3 Month</Option>
                    <Option value="4 Month">4 Month</Option>
                  </Select> */}
                {/* </div> */}
              </div>
            </div>
            <Spin spinning={this.state.loading} delay={500}>
            <div className="transList">
              {(requestData.length>0) ? 
              requestData.map((item, i) => {
                return (
                  <div key={i} className="transListItem  d-flex justify-content-between">
                    <div className="transItemDetail">
                      <h5>{item.service_name}</h5>
                      <p>{moment(item.create_on).format("D MMMM")}</p>
                    </div>
                    <div className="transItemPrice">
                      <h4>-{item.total_payment} AED</h4>
                    </div>
                  </div>
                )
              }): <h5>Data Not Found</h5>}
            </div>
            </Spin>
          </div>
          <div className="footerMenuWrap">
            <div className="menuMain">
              <ul>
                <li><Link to="/"><img src="./images/icons/menu-1.png" alt="menu" /></Link></li>
                <li><Link to="tracking"><img src="./images/icons/menu-active-3.png" alt="menu" /></Link></li>
                <li className="menuPlus"><Link to="/add-request/new_request"><img src="./images/icons/plus.png" alt="menu" /></Link></li>
                <li><Link to="transactions"><img src="./images/icons/menu-2.png" alt="menu" /></Link></li>
                <li><Link to="menu"><img src="./images/icons/menu-4.png" alt="menu" /></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
