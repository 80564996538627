import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, withRouter, Redirect
} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { isEmpty } from 'lodash';
import Home from "../views/Home/container";
import Menu from "../views/Menu/container";
import StepFirst from "../views/StepFirst/container";
import StepSecond from "../views/StepSecond/container";
import StepThard from "../views/StepThard/container";
import SignIn from "../views/SignIn/container";
import ForgotPassword from "../views/ForgotPassword/container";
import ForgotAffiliate from "../views/ForgotAffiliate/container";
import SignUp from "../views/SignUp/container";
import SignUpDetail from "../views/SignUpDetail/container";
import Otp from "../views/Otp/container";
import AddRequest from "../views/AddRequest/container";
import Affiliate from "../views/Affiliate/container";
import Track from "../views/Track/container";
import Receipt from "../views/Receipt/container";
import Tracking from "../views/Tracking/container";
import Transactions from "../views/Transactions/container";
import Splash from "../views/Splash/container";
import Chatboard from "../views/Chatboard/container";
import PartnerWithus from "../views/PartnerWithus/container"
import privacy from "../views/Privacy/container"
import profile from "../views/Profile/container"
import SupportHelp from "../views/SupportHelp/container"
import 'antd/dist/antd.css'
import "../App.scss";
import DocumentMethod from "../views/AddRequest/DocumentMethod/container";
import io from "socket.io-client";
const ENDPOINT = "https://dbcapi.webvilleedemo.xyz";

const PrivateRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem('token')

  const isAuthed = user ? true : false

  // var isAuthed = '';

  // if(user !== 'undefined' && user !== undefined && user !== '' && user !== null)
  // {
  //    isAuthed = true;
  // }else{
  //    isAuthed = false;
  // }

  return (
    <Route {...rest} exact
      render={props => (
        isAuthed ? (<div>
          {
            React.createElement(component, props)
          }
        </div>)
          :
          (
            <Redirect
              to={{
                pathname: '/splash',
                state: { from: props.location }
              }}
            />
          )
      )}
    />
  )
}

const PublicRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem('token')
  const isAuthed = user ? true : false
  // loggedIn = isAuthed
  return (
    <Route {...rest} exact
      render={props => (
        isAuthed ? (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location }
            }}
          />
        )
          : (<div>
            {
              React.createElement(component, props)
            }
          </div>)
      )}
    />
  )
}

const CommonRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem('token')

  // const isAuthed = user ? true : false
  const userguest = window.localStorage.getItem('role')

  const isAuthed = user ? true : (userguest == 4) ? true : false

  return (
    <Route {...rest} exact
      render={props => (
        isAuthed ? (<div>
          {
            React.createElement(component, props)
          }
        </div>)
          :
          (
            <Redirect
              to={{
                pathname: '/splash',
                state: { from: props.location }
              }}
            />
          )
      )}
    />
  )
}

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      user: {},
      socket: null
    }
  }

  componentDidMount() {
    const userID = window.localStorage.getItem('userID')
    const socket = io.connect(ENDPOINT);
    this.setState({ socket })
    if (userID) {
        socket.emit('join_chat', userID);
        socket.on("join_chat_ack",function(data){
          console.log("join chat :- "+data);
        })
      // socket.on('join_chat', function (userID) {
      //   socket.join(userID);
      //   socket.user_id = userID;
      //   io.sockets.socket(userID).emit('join_chat_ack', "socket join success" + userID);
      // });
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (isEmpty(props.user) !== isEmpty(state.user)) {
      return { isLoading: false, user: props.user }
    }

    // Return null to indicate no change to state.
    return null;
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            {this.state.socket && <CommonRoute exact path="/" component={(props) => <Home socket={this.state.socket} {...props} />} />}
            {this.state.socket && <CommonRoute exact path="/Home" component={(props) => <Home socket={this.state.socket} {...props} />} />}
            <Route exact path="/StepFirst" component={StepFirst} {...this.props} />
            <Route exact path="/StepSecond" component={StepSecond} {...this.props} />
            <Route exact path="/StepThard" component={StepThard} {...this.props} />
            <PublicRoute exact path="/signin" component={SignIn} {...this.props} />
            <Route exact path="/affiliate-login/:id" component={Affiliate} {...this.props} />
            <PublicRoute exact path="/signup" component={SignUp} {...this.props} />
            <Route exact path="/otp" component={Otp} {...this.props} />
            <PrivateRoute exact path="/signup-detail" component={SignUpDetail} {...this.props} />
            <Route exact path="/add-request/:id" component={AddRequest} {...this.props} />
            <Route exact path="/required-documents" component={AddRequest} {...this.props} />
            <PublicRoute exact path="/" component={SignIn} {...this.props} />
            <Route exact path="/fees-summary" component={AddRequest} {...this.props} />
            <Route exact path="/forgot-password" component={ForgotPassword} {...this.props} />
            <Route exact path="/affiliate-login/id/forgot-password" component={ForgotAffiliate} {...this.props} />
            <Route exact path="/address" component={AddRequest} {...this.props} />
            <Route exact path="/collection-type" component={AddRequest} {...this.props} />
            <PrivateRoute exact path="/menu" component={Menu} {...this.props} />
            <Route exact path="/payment-method" component={AddRequest} {...this.props} />
            <Route exact path="/final-fees-breakdown" component={AddRequest} {...this.props} />
            <Route exact path="/document-method" component={AddRequest} {...this.props} />
            <Route exact path="/upload" component={AddRequest} {...this.props} />
            <Route exact path="/collection-timings" component={AddRequest} {...this.props} />
            <Route exact path="/payment-processing" component={AddRequest} {...this.props} />
            <Route exact path="/thank-you" component={AddRequest} {...this.props} />
            <PrivateRoute exact path="/track/:id" component={Track} {...this.props} />
            <PrivateRoute exact path="/receipt/:id" component={Receipt} {...this.props} />
            <PrivateRoute exact path="/tracking" component={Tracking} {...this.props} />
            <PrivateRoute exact path="/transactions" component={Transactions} {...this.props} />
            <Route exact path="/splash" component={Splash} {...this.props} />
            <Route exact path="/partner" component={PartnerWithus} {...this.props} />
            <Route exact path="/privacy" component={privacy} {...this.props} />
            <Route exact path="/support" component={SupportHelp} {...this.props} />
            <PrivateRoute exact path="/profile" component={profile} {...this.props} />
            {this.state.socket && <Route exact path="/chatboard/:id" render={(props) => <Chatboard socket={this.state.socket} {...props} />} />}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default withRouter(withCookies(App))
// export default App;