import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Input, Select, Form, message, Spin } from 'antd';

// const CollectionType = () => {
const { Option } = Select;
export default class CollectionType extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      collectionType: '',
      CenterSelected: '',
      value: '',
      selectedItems: '',
      centersItem: [],
      all_request_data: [],
      loading: false,
      pickupCharges: 0
    }
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    // const service_type = window.localStorage.getItem('service_type')
    const datacharge = await this.props.userSetting();
    if (datacharge.value.status === 200) {
      this.setState({ pickupCharges: datacharge.value.data.meta_value })
    }
    const service_id = window.localStorage.getItem('service_id')
    if (service_id) {
      let dataobj = {
        service_id: service_id
      }
      var centerData = ''
      const datas = await this.props.getServicesbyID(dataobj)

      if (datas.value.status === 200) {
        // console.log(datas.value.data.service_centers);
        centerData = datas.value.data.service_centers
      } else if (datas.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }

      if (centerData.length > 0) {
        const datacenters = await this.props.fetchAllCenters();

        if (datacenters.value.status === 200) {
          // console.log(datacenters.value.data);
          var filtered = datacenters.value.data.filter(function (item) {
            return centerData.indexOf(item._id) !== -1;
          });

          // console.log("test centers " + filtered);
          this.setState({ centersItem: filtered })
        } else if (datacenters.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/signin');
        }
      }
    } else {
      this.props.history.push('/home');
    }

    const request_id = window.localStorage.getItem('request_id');
    if (request_id) {
      const reqobj = {
        service_req_id: request_id
      }
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {
        // console.log(datar, 'jhgj');
        this.setState({ all_request_data: datar.value.data });
        console.log(datar.value.data.request_type);
        this.setState({ value: datar.value.data.request_type })
        if (datar.value.data.request_type === 'drop-off') {
          this.setState({ selectedItems: datar.value.data.drop_off_center })
        }
      }else if (datar.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }
    }
    this.setState({ loading: false });
  }

  handleChangecenter = (CenterSelected) => {
    this.setState({ selectedItems: CenterSelected })
    // console.log(`selected ${CenterSelected}`);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }



  handleSubmit = async () => {
    // this.setState({ loading: true });
    const { value, selectedItems } = this.state
    var is_submit = true
    if (value === '' || value.trim() === '') {
      is_submit = false
    }
    if (value === "drop-off") {
      if (selectedItems === '' || selectedItems.trim() === '') {
        is_submit = false
      } else {
        this.setState({ selectedItems: '' })
      }
    }

    const request_id = window.localStorage.getItem('request_id')

    const userRole = window.localStorage.getItem('role')

    if (is_submit === true) {

      if (userRole == 4) {  //check guest login

        if (value === "drop-off") {
          this.props.history.push('/final-fees-breakdown');
        } else {
          this.props.history.push('/collection-timings');
        }
        return false;
      }
      localStorage.setItem('request_type', value)
      // console.log("echk return");
      const reqData = {
        service_req_id: request_id,
        request_type: value,
        drop_off_center: selectedItems,
      }
      if (value === "drop-off") {
        const datasuccess = await this.props.UpdateRequests(reqData);
        if (datasuccess.value.status === 200) {
          // message.success(datasuccess.value.msg);
          setTimeout(() => {
            this.props.history.push('/final-fees-breakdown');
            // this.setState({ loading: false, visible: false });
          }, 1000);
        }else if (datasuccess.value.status === 401) {
          localStorage.clear();
          this.props.history.push('/signin');
        } else {
          message.error(datasuccess.value.msg);
        }
      } else {
        const datasuccess = await this.props.UpdateRequests(reqData);
        if (datasuccess.value.status === 200) {
          // message.success(datasuccess.value.msg);
          setTimeout(() => {
            this.props.history.push('/collection-timings');
            // this.setState({ loading: false, visible: false });
          }, 1000);
        } else {
          message.error(datasuccess.value.msg);
        }
      }
    } else {
      message.error("Please insert all Fields");
    }
  }

  render() {
    const { centersItem, selectedItems, value, pickupCharges } = this.state
    const collectionType = value === 'pickup';
    // console.log(centersItem)
    return (
      <div><Spin spinning={this.state.loading} delay={500}>
        <Form>
          <div className="addRequestInner">
            <div className="addReqContainer">
              <h5 className="heading_5">Document collection type for your request</h5>
              <div className="collectionTypeRadios">
                <div className="collectionTypeRadiosCol">

                  <div className="customRadio">
                    <Input type="radio" checked={(value === 'pickup') ? true : false} name="collectionType" value="pickup" onChange={this.handleChange} />
                    <div className="customRadioContent">
                      <img src="./images/icons/pickup.png" />
                      <div className="cr_rightContent">
                        <h4>Pickup</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collectionTypeRadiosCol">
                  <div className="customRadio">
                    <Input type="radio" name="collectionType" checked={(value === 'drop-off') ? true : false} value="drop-off" onChange={this.handleChange} />
                    <div className="customRadioContent">
                      <img src="./images/icons/drop.png" />
                      <div className="cr_rightContent">
                        <h4>Drop-off</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {value === 'pickup' && (
                <p className="noteTxt">
                  <strong>Note : </strong>
                  <span> {pickupCharges} AED will be charged extra for pickup</span>
                </p>
              )}
              {value === 'drop-off' && (
                <div className="dropOffform">
                  <h5 className="heading_5">Select Center from where you want drop-off</h5>
                  {/* <Select defaultValue="Subcategory" onChange={this.handleChangecenter} className="shadowSelect">
                  <Option value="Dubai Center">Dubai Center</Option>
                  <Option value="Qatar Center">Qatar Center</Option>
                  <Option value="Kuwait Center">Kuwait Center</Option>
                </Select> */}

                  <Select style={{ width: '100%' }} placeholder="Centers" value={(selectedItems) ? selectedItems : 'Choose Center'} onChange={this.handleChangecenter} className="shadowSelect">
                    {centersItem.map(item => {
                      return (<Option key={item._id} value={item._id}>
                        {item.center_name}
                      </Option>)
                    })}
                  </Select>
                  <p className="noteTxt">
                    <strong>Address : </strong>
                    <span> Suite 2508, JBC 1, Cluster G, JLT</span>
                  </p>
                </div>
              )}

            </div>
          </div>
          <div className="addReqSubmit">
            <Input type="submit" onClick={this.handleSubmit} className="btn btn-primary" value="Next" />
            {/* <Link to="/" className="btn btn-primary">Next</Link> */}
          </div>
        </Form></Spin>
      </div>
    );
  };
}

