import React, { Component } from 'react'
import { Link } from "react-router-dom";
import NewRequest from "./NewRequest/container"
import RequiredDocuments from "./RequiredDocuments/container"
import FeesSummary from "./FeesSummary/container"
import Address from "./Address/container"
import CollectionType from "./CollectionType/container"
import PaymentMethod from "./PaymentMethod/container"
import FinalFeesBreakdown from "./FinalFeesBreakdown/container"
import DocumentMethod from "./DocumentMethod/container"
import UploadDocument from "./Upload/container";
import CollectionTimings from "./CollectionTimings/container";
import PaymentProcessing from "./PaymentProcessing/container";
import ThankYou from "./ThankYou/container";
import "./styles.scss"
import 'antd/dist/antd.css';
export default class AddRequest extends Component {
    render() {
        const { location: { pathname }, match:{path} } = this.props
        const service_id = window.localStorage.getItem('service_id')
        const request_type = window.localStorage.getItem('request_type')
        return (<>
            <div>
                <div className="addRequest">
                    {path === "/add-request/:id" &&
                        <div>
                            <div className="addReqHeading">
                                <h2><Link to="/"><img src="../images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                            </div>
                            <div className="addReqSteps">
                                <div className="addReqStepsInner">
                                    <div className="stepItem currentStep">
                                        <span className="stepDot">
                                            <span className="stepTooltip">Add Request</span>
                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                </div>
                            </div>
                            <NewRequest history={this.props.history} match={this.props.match}/>
                        </div>}

                    {pathname === "/required-documents" &&
                        <div>
                            <div className="addReqHeading">
                                <h2><Link to={'add-request/'+service_id}><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                            </div>
                            <div className="addReqSteps">
                                <div className="addReqStepsInner">
                                    <div className="stepItem completeStep">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem currentStep">
                                        <span className="stepDot">
                                            <span className="stepTooltip">Required Documents</span>
                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                </div>
                            </div>
                            <UploadDocument history={this.props.history} />
                        </div>}


                    {pathname === "/fees-summary" && <div className="addRequestInner">
                        <div className="addReqHeading">
                            <h2><Link to="required-documents"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                        </div>
                        <div className="addReqSteps">
                            <div className="addReqStepsInner">
                                <div className="stepItem completeStep">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem currentStep">
                                    <span className="stepDot">
                                        <span className="stepTooltip">Fees Summary</span>
                                    </span>
                                </div>
                                <div className="stepItem">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem">
                                    <span className="stepDot"></span>
                                </div>
                            </div>
                        </div>
                        <FeesSummary history={this.props.history} />
                    </div>}

                    {pathname === "/address" && <div>
                        <div className="addReqHeading">
                            <h2><Link to="fees-summary"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                        </div>
                        <div className="addReqSteps">
                            <div className="addReqStepsInner">
                                <div className="stepItem completeStep">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem currentStep">
                                    <span className="stepDot">
                                        <span className="stepTooltip">Your Address</span>
                                    </span>
                                </div>
                                <div className="stepItem">
                                    <span className="stepDot"></span>
                                </div>
                            </div>
                        </div>
                        <Address history={this.props.history} />
                    </div>}

                    {pathname === "/collection-type" && <div>
                        <div className="addReqHeading">
                            <h2><Link to="/address"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                        </div>
                        <div className="addReqSteps">
                            <div className="addReqStepsInner">
                                <div className="stepItem completeStep">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem currentStep">
                                    <span className="stepDot">
                                        <span className="stepTooltip">Collection Type</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <CollectionType history={this.props.history} />
                    </div>}

                    {pathname === "/payment-method" && <div>
                        <div className="addReqHeading">
                            <h2><Link to="/final-fees-breakdown"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                        </div>
                        <div className="addReqSteps lastSteps">
                            <div className="addReqStepsInner">
                                <div className="stepItem completeStep">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem currentStep">
                                    <span className="stepDot">
                                        <span className="stepTooltip">Payment Method</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <PaymentMethod history={this.props.history} />
                    </div>}

                    {pathname === "/final-fees-breakdown" && <div>
                        <div className="addReqHeading">
                        <h2><Link to={(request_type=='pickup') ? '/collection-timings': '/collection-type'}><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                        </div>
                        <div className="addReqSteps lastSteps">
                            <div className="addReqStepsInner">
                                <div className="stepItem completeStep">
                                    <span className="stepDot"></span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">
                                    </span>
                                </div>
                                <div className="stepItem completeStep">
                                    <span className="stepDot">

                                    </span>
                                </div>
                                <div className="stepItem currentStep">
                                    <span className="stepDot">
                                        <span className="stepTooltip">Final Fees Breakdown</span>
                                    </span>
                                </div>
                                <div className="stepItem">
                                    <span className="stepDot"></span>
                                </div>
                            </div>
                        </div>
                        <FinalFeesBreakdown history={this.props.history} />
                    </div>}
                    {pathname === "/document-method" &&
                        <div className="documentMethod">
                            <div className="addReqHeading">
                                <h2><Link to="fees-summary"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                            </div>
                            <div className="addReqSteps lastSteps">
                                <div className="addReqStepsInner">
                                    <div className="stepItem completeStep">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem completeStep">
                                        <span className="stepDot">
                                        </span>
                                    </div>
                                    <div className="stepItem currentStep">
                                        <span className="stepDot">
                                            <span className="stepTooltip">Document Method</span>
                                        </span>
                                    </div>
                                    <div className="stepItem ">
                                        <span className="stepDot">
                                        </span>
                                    </div>
                                    <div className="stepItem ">
                                        <span className="stepDot">

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <DocumentMethod history={this.props.history} />
                        </div>}

                    {/* {pathname === "/upload" &&
                    <div className="documentMethod">
                            <div className="addReqHeading">
                                <h2><Link to={(request_type=='pickup') ? '/collection-timings': '/collection-type'}><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                            </div>
                            <div className="addReqSteps lastSteps">
                                <div className="addReqStepsInner">
                                    <div className="stepItem completeStep">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem completeStep">
                                        <span className="stepDot">
                                        </span>
                                    </div>
                                    <div className="stepItem completeStep">
                                        <span className="stepDot">
                                        </span>
                                    </div>
                                    <div className="stepItem currentStep">
                                        <span className="stepDot">
                                        <span className="stepTooltip">Document Upload</span>
                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="uploadMain">
                            <UploadDocument history={this.props.history} /></div>
                        </div>} */}
                        


                    {pathname === "/collection-timings" &&
                        <div>
                            <div className="addReqHeading">
                                <h2><Link to="/collection-type"><img src="./images/icons/left.png" alt="left" /><span>New Request</span></Link></h2>
                            </div>
                            <div className="addReqSteps lastSteps">
                                <div className="addReqStepsInner">
                                    <div className="stepItem completeStep">
                                        <span className="stepDot"></span>
                                    </div>
                                    <div className="stepItem currentStep">
                                        <span className="stepDot">
                                            <span className="stepTooltip">Collection Timings</span>
                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot">

                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot">

                                        </span>
                                    </div>
                                    <div className="stepItem">
                                        <span className="stepDot"></span>
                                    </div>
                                </div>
                            </div>
                            <CollectionTimings history={this.props.history} />
                        </div>}
                    {pathname === "/payment-processing" &&
                        <div>
                            <div className="addReqHeading">
                                <h2><Link to="required-documents"><img src="./images/icons/left.png" alt="left" /></Link></h2>
                            </div>
                            <PaymentProcessing history={this.props.history} />
                        </div>}

                        {pathname === "/thank-you" &&
                        <div>
                            <ThankYou history={this.props.history} />
                        </div>}

                </div>
            </div>

        </>
        );
    }
}
