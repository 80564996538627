import { connect } from 'react-redux'
import PartnerWithusComponent from './component'
import {getContent} from '../../store/user/duck'

const PartnerWithusContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getContent
  }
  // Map actions to dispatch and props
)(PartnerWithusComponent)

export default PartnerWithusContainer
