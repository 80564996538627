import { connect } from 'react-redux'
import { getAllServices, uploadImage, getServicesbyID} from '../../../store/service/duck'
import RequiredDocuments from './component'

const RequiredDocumentsContainer = connect(
  // Map state to props
  state => ({
  }),
  { 
    getAllServices,
    uploadImage,
    getServicesbyID
  }
)(RequiredDocuments)

export default RequiredDocumentsContainer