import React from "react";
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCFR9hzxNNeMLv8TY_kfKgHBEFiBrG5dXE");
Geocode.enableDebug();

const AsyncMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={props.defaultZoom} defaultCenter={{ lat: props.mapPosition.lat, lng: props.mapPosition.lng }}>
      {/* For Auto complete Search Box */}
      {console.log({ props })}
      <div className="addressSearchFieldinMap">
        <Autocomplete style={{}} placeholder={props.address} onPlaceSelected={props.onPlaceSelected} types={[]} />
      </div>
      {/*Marker*/}
      <Marker
        google={props.google}
        name={"Dolores park"}
        draggable={true}
        onDragEnd={props.onDragEnd}
        position={{ lat: props.markerPosition.lat, lng: props.markerPosition.lng }}
      />
      <Marker />
      {/* InfoWindow on top of marker */}
      <InfoWindow onClose={props.onClose} position={{ lat: props.markerPosition.lat + 0.0018, lng: props.markerPosition.lng }}>
        <div>
          <span style={{ padding: 0, margin: 0 }}>{props.address}</span>
        </div>
      </InfoWindow>
    </GoogleMap>
  ))
);

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        //  console.log( 'city', city, area, state );
        this.props.onSelectAddress(address);
        this.props.latValue(this.state.mapPosition.lat);
        this.props.lngValue(this.state.mapPosition.lng);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          },
          mapPosition: {
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }
        });
      },
      error => {
        console.error(error);
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }

  getCity = addressArray => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "administrative_area_level_2" === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = addressArray => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("sublocality_level_1" === addressArray[i].types[j] || "locality" === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = addressArray => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "administrative_area_level_1" === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onInfoWindowClose = event => { };

  onPlaceSelected = place => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.props.onSelectAddress(address);
    this.props.latValue(latValue);
    this.props.lngValue(lngValue);
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      }
    });
  };

  onMarkerDragEnd = event => {
      // console.log( 'event', event );
      this.setState({address:""})
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),
      addressArray = [];
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        this.props.onSelectAddress(address);
        this.props.latValue(newLat);
        this.props.lngValue(newLng);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          }
        });
      },
      error => {
        console.error(error);
      }
    );
  };
  render() {
    let map;
    if (this.props.center.lat !== undefined) {
      // console.log('jfidjfidjfidj', this.props)
      map = (
        <div style={{ height: "600px" }}>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCFR9hzxNNeMLv8TY_kfKgHBEFiBrG5dXE&libraries=places"
            loadingElement={<div></div>}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            google={this.props.google}
            defaultZoom={this.props.zoom}
            mapPosition={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
            placeholder={this.state.address}
            onPlaceSelected={this.onPlaceSelected}
            onDragEnd={this.onMarkerDragEnd}
            markerPosition={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
            onClose={this.onInfoWindowClose}
            address={this.state.address}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
