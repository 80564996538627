import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Modal, Button, Spin } from 'antd';
import moment from 'moment';

export default class ThankYou extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      visible: true,
      confirmLoading: false,
      all_request_data: [],
      transaction_data: {},
      requestData: [],
      pdfLink:''
    }
  }

  async componentDidMount() {
    document.title = 'Thankyou | DBC'
    this.setState({ loading: true });
    const request_id = window.localStorage.getItem('request_id');
    // const request_id ="5e9ee678c2b25226844d7fca";
    const service_req = {
      service_req_id: request_id,
    }
    const datareq = await this.props.getRequestbyId(service_req);
    if (datareq.value.status === 200) {
      // console.log(datareq.value.data, 'helllooo');
      this.setState({ requestData: datareq.value.data });
    }else if (datareq.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }

    if (this.state.requestData.payment_method === 'card') {
      const reqobj = {
        service_req_id: request_id,
        page_no: 0
      }
      const datar = await this.props.getRequestPay(reqobj);
      if (datar.value.status === 200) {
        // console.log(datar.value.data, 'helllooo');
        this.setState({ all_request_data: datar.value.data, transaction_data: JSON.parse(datar.value.data.transaction_data) });
      }else if (datar.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }
    }
    const reqPdf = {
      service_req_id: request_id,
    }
    const dataPDF = await this.props.createPdf(reqPdf);
    if (dataPDF.value.status === 200) {
      this.setState({ pdfLink: dataPDF.value.data });
    }else if (dataPDF.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
   
    this.setState({ loading: false });

    localStorage.removeItem("service_id");
    // localStorage.removeItem("request_id");
    localStorage.removeItem("service_type");
    localStorage.removeItem("agreeCheck");
    localStorage.removeItem("cardNumber");
    localStorage.removeItem("request_type");
  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };

  handleCancel = () => {
    localStorage.removeItem("service_id");
    // localStorage.removeItem("request_id");
    localStorage.removeItem("service_type");
    localStorage.removeItem("agreeCheck");
    localStorage.removeItem("cardNumber");
    localStorage.removeItem("request_type");
    this.setState({
      visible: false,
    });
  };

  render() {
    const {pdfLink, visible, confirmLoading, ModalText, all_request_data, transaction_data, requestData } = this.state;
    const username = window.localStorage.getItem('username')
    const request_id = window.localStorage.getItem('request_id');
    return (
      <div>
        <div className="profilePages">
          <div className="profile-withbottomButton">
            <h1 className="profileHeading">Thank you for your <br />request</h1>
            <div className="profile-container">
              <div className="profileThankyou">
                <div className="thankyouImage">
                  <img src="./images/thank-you.png" />
                </div>
                <h2>Thank you</h2>
                <p>Thank you for your valuable request we are looking forward to process it you can track the status of your request </p>
              </div>
            </div>
          </div>
          <div className="profileBottomButton">
            <Link to={(all_request_data.payment_method === 'card') ? `/track/${request_id}` : `/track/${request_id}` } className="btn btn-primary">Track</Link>
          </div>
        </div>
        
        <Modal
          visible={visible}
          className="thankyouModal"
        >
          <Spin spinning={this.state.loading} delay={500}><div className="thanksModalCheck">
            <img src="./images/icons/checkmark_circle.png" />
          </div>
          {(all_request_data.payment_method === 'card') ? <h3 class="text-center">Payment Successful</h3> :
          <h6 class="text-center">"Your order has been placed, please place the exact amount mentioned below inside an envelope along with the required documents."</h6>}
          <div className="requestAmount">
            <h4>{(all_request_data.payment_method === 'card') ? all_request_data.total_payment : requestData.total_premium} AED</h4>
            <p>Request Amount</p>
          </div>
          <table className="table thanksModalTable">
            <tbody>
              <tr>
                <td>
                  <h5>From:</h5>
                  <p>{(all_request_data.payment_method === 'card') ? transaction_data.card_type + ' ' + all_request_data.payment_method : requestData.payment_method}  </p>
                </td>
                <td>
                  <h5>{username}</h5>
                  <p>{(all_request_data.payment_method === 'card') ? transaction_data.scheme + '   *' + transaction_data.last4 : ""}  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Date:</h5>
                </td>
                <td>
                  <p>{(all_request_data.payment_method === 'card') ? moment(all_request_data.create_on).format("D MMMM  YYYY") : moment(requestData.create_on).format("D MMMM  YYYY") }  </p>
                  {/* <p>12:48 PM</p> */}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="downloadReceipt">
          <a  href={`https://dbcapi.webvilleedemo.xyz/uploads/${pdfLink}`}  target="_blank" download>
          <img src="./images/icons/icon-bill.png" />Download Receipt</a>
            {/* <Link to="" download></Link> */}
          </div>
          <div className="closeModal">
            <Button onClick={this.handleCancel}>Close</Button>
            <p>Just let us know how you would like to provide the requested documents for your service</p>
          </div></Spin>
        </Modal>
      </div>
    );
  }
}
