import { connect } from 'react-redux'
import SignupComponent from './component'
import {  signupUser, userClearPhase, codeClearPhase } from '../../store/user/duck'

const SignupContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    signupPhase: state.user.signupPhase
  }),
  {
    signupUser,
    userClearPhase,
    codeClearPhase
  }
  // Map actions to dispatch and props
)(SignupComponent)

export default SignupContainer
