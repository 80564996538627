import { connect } from 'react-redux'
import ChatboardComponent from './component'
import { getTrackingData, getTransactionByfilter, getAllChatHistory } from '../../store/request/duck'
import { uploadImage } from '../../store/service/duck'

const ChatboardContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getTrackingData,
    getTransactionByfilter,
    getAllChatHistory,
    uploadImage
  }
  // Map actions to dispatch and props
)(ChatboardComponent)

export default ChatboardContainer
