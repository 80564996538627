import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import 'antd/dist/antd.css';
import { Input, message, Form, Spin } from 'antd';

// const CollectionType = () => {

export default class PaymentMethod extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      disable: false,
      paymentId: "test0123456789",
      payAllData: [],
      totalPayment: 0
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const service_id = window.localStorage.getItem('service_id')
    if (!service_id) {
      this.props.history.push('/home');
    }
    const request_id = window.localStorage.getItem("request_id");
    if (request_id) {
      const reqobj = {
        service_req_id: request_id
      };
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {
        // console.log(datar.value.data.total_premium)
        this.setState({ totalPayment: datar.value.data.total_premium, loading: false })
      }
    }
  }

  handleCard = (e) => {
    let disable = false
    const self = this
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.cardNumber === '' || self.state.cardNumber === undefined) {
        disable = true
      }
      if (self.state.cardMonth === '' || self.state.cardMonth === undefined) {
        disable = true
      }
      if (self.state.cardYear === '' || self.state.cardYear === undefined) {
        disable = true
      }
      if (self.state.cardCvv === '' || self.state.cardCvv === undefined) {
        disable = true
      }
      self.setState({ disable })
    })
  }

  handleSubmit = async (event) => {
    const userRole = window.localStorage.getItem('role');
    if (userRole == 4) {
      this.props.history.push('/signin');
      return false;
    }
    // event.preventDefault();
    // this.setState({ loading: true });

    const { value, cardNumber, cardMonth, cardYear, cardCvv, paymentId, totalPayment } = this.state
    // console.log(cardNumber, cardMonth, cardYear, cardCvv, 'lplplplpllplplplpl')
    var is_submit = true
    const request_id = window.localStorage.getItem('request_id')
    if (value === '' || value.trim() === '') {
      is_submit = false
    }
    if (value === 'card') {
      if (cardNumber === '' || cardNumber.trim() === '') {
        is_submit = false
      }
      if (cardMonth === '' || cardMonth.trim() === '') {
        is_submit = false
      }
      if (cardYear === '' || cardYear.trim() === '') {
        is_submit = false
      }
      if (cardCvv === '' || cardCvv.trim() === '') {
        is_submit = false
      }
    }

    if (is_submit === true) {
      this.setState({ loading: true });
      if (value === 'card') {
        fetch("https://api.sandbox.checkout.com/tokens", {
          "method": "POST",
          "headers": {
            "Authorization": "pk_test_730afa0b-7388-44f0-9235-7cdc8facd984",
            "Content-Type": "application/json",
            "accept": "application/json"
          },
          "body": JSON.stringify({
            type: value,
            number: cardNumber,
            expiry_month: cardMonth,
            expiry_year: cardYear,
            cvv: cardCvv,
          })
        })
          .then(response => response.json())
          .then(response => {
            // console.log(response)
            if (response.token) {
              fetch("https://api.sandbox.checkout.com/payments", {
                "method": "POST",
                "headers": {
                  "Authorization": "sk_test_eec770b0-7ed9-4546-abef-08c5c4384c4f",
                  "Content-Type": "application/json",
                  "accept": "application/json"
                },
                "body": JSON.stringify(
                  {
                    source: {
                      type: "token",
                      token: response.token
                    },
                    amount: totalPayment,
                    currency: "AED",
                    "3ds": {
                      enabled: true,
                      attempt_n3d: true,
                      eci: "05",
                      cryptogram: "AgAAAAAAAIR8CQrXcIhbQAAAAAA=",
                      xid: "MDAwMDAwMDAwMDAwMDAwMzIyNzY=",
                      version: "2.0.1"
                    }
                  }
                )
              })
                .then(res => res.json())
                .then(async (res) => {
                  if (res.response_code === '10000') {
                    const reqData = {
                      service_req_id: request_id,
                      payment_method: value,
                      status: 1,
                      transaction_data: res.source,
                      payment_id: res.id,
                      transaction_status: 1
                    }
                    const datasuccess = await this.props.UpdateRequests(reqData);
                    if (datasuccess.value.status === 200) {
                      // message.success(datasuccess.value.msg);
                      this.props.history.push('/payment-processing');
                      // setTimeout(() => {
                      //   // console.log('897897897878978789')
                      //   this.props.history.push('/thank-you');
                      //   // this.setState({ loading: false, visible: false });
                      // }, 3000);
                      this.setState({ loading: false });
                    } else {
                      message.error(datasuccess.value.msg);
                      this.setState({ loading: false });
                    }
                  } else {
                    // message.error(res.response_code + ' please retry');
                    message.error("Invalid card details please check");
                    this.setState({ loading: false });
                  }
                })
                .catch(error => {
                  message.error(error);
                });
            } else {
              message.error(response.error_codes[0] + ' please recheck');
              this.setState({ loading: false });
            }

          })
          .catch(err => {
            message.error(err);
          });
      } else {
        const reqData = {
          service_req_id: request_id,
          payment_method: value,
          status: 1,
          payment_id: request_id
        }
        const datasuccess = await this.props.UpdateRequests(reqData);
        if (datasuccess.value.status === 200) {
          this.setState({ loading: false });
          //   message.success(datasuccess.value.msg);
          this.props.history.push('/payment-processing');
          // setTimeout(() => {
          //   this.props.history.push('/thank-you');
          //   // this.setState({ loading: false, visible: false });
          // }, 3000);
        } else {
          message.error(datasuccess.value.msg);
        }
      }
    }



  }

  checkMonth(e) {
    if (e.target.value > 12) {
      e.target.value = "";
      // console.log(e.target.value)
    }
    // console.log(e.target.value.length, 'length')
   
  }

  firstMethod(e){
    if (e.target.value.length >1) {
      // console.log(e.target.value.length, 'length');
      // return false
      e.preventDefault();
    }
  }

  render() {
    const collectionType = this.state.value === 'pickup';
    return (
      <div><Spin spinning={this.state.loading} delay={500}>
        <Form>
          <div className="addRequestInner">
            <div className="addReqContainer">
              <div className="ShowTotalPayment">
                <p>Total Payment</p>
                <h4>{this.state.totalPayment} AED</h4>
              </div>
              <h5 className="heading_5">Please select the mode of payment</h5>
              <div className="customRadio">
                <Input className="anchor-link" type="radio" name="collectionType" value="cash" onChange={this.handleChange} />
                <div className="customRadioContent">
                  <img src="./images/icons/cash.png" />
                  <div className="cr_rightContent">
                    <h4>Cash Payment</h4>
                    <p>Pay cash to our agent at time of document collection</p>
                  </div>
                </div>
              </div>
              <div className={this.state.value === 'link-payment' && ('collapseActive')}>
                <div className="customRadio customRadioPaymentMain">
                  <div className="customRadioPayment">
                    <Input className="anchor-link" type="radio" name="collectionType" value="link-payment"  onChange={this.handleChange} />
                    <div className="customRadioContent">
                      <img src="./images/icons/link-payment.png" />
                      <div className="cr_rightContent">
                        <h4>Payment via link</h4>
                        <p>Pay via link to your phone & email</p>
                      </div>
                    </div>
                  </div>
                  {this.state.value === 'link-payment' && (
                    <div className="hiddenPaymentForm">
                      <div className="d-flex ">
                        <div className="phoneDropDown">
                          <select className="form-control mdu-drop" name="std_code" >
                            <option value="971">+971</option>
                            <option value="972">+972</option>
                            <option value="973">+973</option>
                            <option value="974">+974</option>
                            <option value="975">+975</option>
                          </select>
                        </div>
                        <input type="text" placeholder="Eg:5687833333" className="form-control" required />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={this.state.value === 'card' && ('collapseActive')}>
                <div className="customRadio customRadioPaymentMain">
                  <div className="customRadioPayment">
                    <Input required type="radio" name="collectionType" value="card" onChange={this.handleChange} />
                    <div className="customRadioContent">
                      <img src="./images/icons/card.png" />
                      <div className="cr_rightContent">
                        <h4>Card Payment</h4>
                        <p>Pay through debit or credit card</p>
                      </div>
                    </div>
                  </div>
                  {this.state.value === 'card' && (
                    <div className="hiddenPaymentForm">
                      <div className="cardPaymentForm">
                        <div className="form-group">
                          <p className="formLabel">Card Number</p>
                          <Input className="anchor-link" required type="number" name="cardNumber" onChange={this.handleCard} onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16) }} className="form-control"></Input>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="formLabel">Exp</p>
                            <div className="expInput">
                              <Input required type="number" name="cardMonth" onChange={this.handleCard} className="form-control" placeholder="MM" onKeyPress={(e) => this.firstMethod(e)} onKeyUp={this.checkMonth} ></Input>
                              <Input required type="number" onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                              }} name="cardYear" onChange={this.handleCard} className="form-control" placeholder="YY"></Input>
                            </div>
                          </div>
                          <div className="col-6">
                            <p className="formLabel">CVV</p>
                            <Input required type="number" onInput={(e) => {
                              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                            }} name="cardCvv" onChange={this.handleCard} className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
          <div className="addReqSubmit">
            <Input type="submit" onClick={this.handleSubmit} className="btn btn-primary" value="Next" />
          </div></Form></Spin>
      </div>
    );
  };
}

// export default CollectionType;
