import React from "react";
// import { Formik, ErrorMessage } from "formik";
import {BrowserRouter as  Link } from "react-router-dom";
import "./styles.scss";


const StepThard = () => {

  return (
    <div>
      <div className="appStartingScreen">
      <div className="appStartingScreenInner">
        <div className="ass-content">
          <div className="ass-image">
            <img src="./images/starting-3.png"  alt="start"/>
          </div>
          <h1 className="heading">Flexible Payments</h1>
          <p>Get more comfortable with the <br/> payments method</p>
        </div>
        <div className="ass-buttons">
        <a href="/signin" className="ass-btn ass-btn-primary">Next</a>
        <a href="/signin"><button className="ass-btn ass-btn-secondary">Skip</button></a>
        </div>
      </div>
      </div>
    </div>
  );
};

export default StepThard;
