import React, { PureComponent } from "react";
// import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import "./styles.scss";
import "antd/dist/antd.css";
import { Collapse, Upload, message, Button, Form, Input, Modal, Spin, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
const Hostname = "https://dbcapi.webvilleedemo.xyz";
const { Panel } = Collapse;

export default class UploadDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      service_docbyId: [],
      err: {},
      message: "",
      selectedItem: [],
      agreeCheck: false,
      front: null,
      back: null,
      documents: [],
      visible: false,
      label: "",
      loop: "",
      doc_name: "",
      pic_url: "",
      feeDt: [],
      feeDatareq: [],
      uploading: false,
    };
  }

  showModal = (label, i) => {
    // console.log(label, i);
    this.setState({
      doc_name: '',
      pic_url: '',
      visible: true,
      label: label,
      loop: i
    });
  };

  handleOk = e => {
    // console.log(e);
    this.setState({
      doc_name: "",
      pic_url: "",
      visible: false
    });
  };

  handleCancel = e => {
    // console.log(e);
    this.setState({
      doc_name: "",
      pic_url: "",
      visible: false
    });
  };
  async componentDidMount() {
    const service_id = window.localStorage.getItem("service_id");
    if (!service_id) {
      this.props.history.push("/home");
    }

    const agreeCheck = window.localStorage.getItem('agreeCheck')
    if (agreeCheck) {
      this.setState({ agreeCheck: agreeCheck })
    }

    this.setState({ loading: true });
    const request_id = window.localStorage.getItem("request_id");
    if (request_id) {
      const reqobj = {
        service_req_id: request_id
      };
      const datar = await this.props.getRequestbyId(reqobj);
      if (datar.value.status === 200) {
        var docklist = []

        if (datar.value.data.documents !== "" && datar.value.data.documents.trim() !== '') {
          // console.log(datar.value.data.documents, 'jhgj');
          docklist = JSON.parse(datar.value.data.documents);
          // const docklist = JSON.parse(datar.value.data.documents);
          // console.log(docklist,'popopopopopopop');

        }
        console.log(docklist, "hhhh");
        this.setState({ feeDt: docklist });

      }else if (datar.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      }
    }
    const service_type = window.localStorage.getItem("service_type");
    let dataobj = {
      service_id: service_id
    };
    const datas = await this.props.getServicesbyID(dataobj);
    // console.log({datas})
    if (datas.value.status === 200) {
      // console.log(datas.value.data);
      var feeData = "";
      if (service_type === "is_new") {
        feeData = datas.value.data.doclist_new;
      } else if (service_type === "is_renewal") {
        feeData = datas.value.data.doclist_renewal;
      } else if (service_type === "is_modify") {
        feeData = datas.value.data.doclist_modify;
      } else if (service_type === "is_cancellation") {
        feeData = datas.value.data.doclist_cancellation;
      }


      const feeDatareq = [];
      for (var j = 0; j < feeData.length; j++) {
        if (feeData[j].doc_type == undefined || feeData[j].doc_type === "Original Document Required") {
          // let temp = {
          //   doc_type: feeData[j].label,
          //   doc_id: feeData[j]._id,
          // };
          feeDatareq.push(feeData[j]);
          // feeDataNew.push(temp);
        };
      };

      const docobj = [];
      const feeDataNew = [];
      // console.log(feeData,"ghghghghghghghghghghghghgh5656565565655")
      for (var i = 0; i < feeData.length; i++) {
        if (feeData[i].doc_type == undefined || feeData[i].doc_type === "Original Document Not Required") {
          let temp = {
            doc_type: feeData[i].label,
            doc_id: feeData[i]._id,
            files: []
          };



          if (this.state.feeDt.length > 0) {
            // console.log(this.state.feeDt, 'llll')
            for (var j = 0; j < this.state.feeDt.length; j++) {
              if (temp.doc_type === this.state.feeDt[j].doc_type) {
                if (this.state.feeDt[j].files) {
                  for (var k = 0; k < this.state.feeDt[j].files.length; k++) {
                    // console.log(this.state.feeDt[i].files[k], 'kjk')
                    temp.files.push(this.state.feeDt[j].files[k]);
                    // temp.files.push([this.state.feeDt[i].files[k]])
                  }
                }
              }
              //   console.log(this.state.feeDt[i])
              //   // temp.files.push(this.state.feeDt[j].files[j])
            }
          }
          if (temp.files.length === 0) {
            temp.files.push({ pic_type: "front", value: "" })
          }


          feeDataNew.push(feeData[i]);
          docobj.push(temp);
        }
      }

      this.setState({ service_docbyId: feeDataNew, loading: false, documents: docobj, feeDatareq: feeDatareq });
    }else if (datas.value.status === 401) {
      localStorage.clear();
      this.props.history.push('/signin');
    }
  }



  handleCheck = (e) => {
    this.setState({ agreeCheck: e.target.checked })
    // console.log(e.target.checked, "iiii")
  }

  handleDoc = e => {
    // console.log(e.target.value);
    this.setState({ doc_name: e.target.value });
  };

  async onChangeHandler(file, i, i2) {
    const userRole = window.localStorage.getItem("role");
    const documents = [...this.state.documents];
    // console.log(file, i, i2);
    this.setState({ loading: true });
    if (file.file.status === "done") {
      // console.log(file, i, i2);
      // const input = { files: [file.file.originFileObj] };
      if (file.file.response.status===200) {
        // console.log(file.file.response.data.filepath, 'helll')
          if (userRole != 4) {
            documents[i].files[i2].value = file.file.response.data.filepath;
            this.setState({ documents });
            this.setState({ loading: false });
          }
      }else if (file.file.response.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        message.error("Please try again")
        this.setState({ loading: false });
      }
      return;
    }else if (file.file.status === 'error') {
      message.error(`${file.file.name} file upload failed.`);
      this.setState({ loading: false });
    }
  }

  async onChangeModal(file) {
    const { loop } = this.state
    // console.log(loop)
    const userRole = window.localStorage.getItem("role");
    const documents = [...this.state.documents];
    this.setState({ loading: true });
    if (file.file.status === "done") {
      // console.log(file);
      // const input = { files: [file.file.originFileObj] };
      if (file.file.response.status===200) {
        // const reader = new FileReader();
        // reader.onload = async function (r) {
          if (userRole != 4) {
            // const formdata = new FormData();
            // await formdata.append("file", input.files[0]);
            // console.log(formdata, 'formdata2')
            // const data = await this.props.uploadImage(formdata);
            // if (data.value.status === 200) {
              documents[loop].files.push({ pic_type: this.state.doc_name, value: file.file.response.data.filepath });
              this.setState({ documents });
              this.setState({ pic_url: file.file.response.data.filepath, doc_name: this.state.doc_name, visible: false, loading: false });
            
          }
        // }.bind(this);
        // reader.readAsDataURL(input.files[0]);
      }else if (file.file.response.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        message.error("Please try again");
        this.setState({ visible: false, loading: false });
      }
      return;
    }else if (file.file.status === 'error') {
      message.error(`${file.file.name} file upload failed.`);
      this.setState({ loading: false });
    }
  }

  handleSubmit = async () => {
    const { documents, agreeCheck } = this.state;
    this.setState({ loading: true });
    var is_submit = true;
    documents.map(d => {
      if (d.files.length == 0 || d.files[0].value == "") {
        is_submit = false;
      }
    });
    if (!agreeCheck) {
      is_submit = false;
    }
    const userRole = window.localStorage.getItem("role");

    if (userRole == 4) {
      //check guest login
      this.props.history.push("/fees-summary");
      return false;
    }

    const request_id = window.localStorage.getItem("request_id");
    if (is_submit === true) {
      localStorage.setItem('agreeCheck', agreeCheck)
      const reqData = {
        service_req_id: request_id,
        documents: JSON.stringify(documents)
      };
      const datasuccess = await this.props.UpdateRequests(reqData);
      if (datasuccess.value.status === 200) {
        //   message.success(datasuccess.value.msg);
        this.setState({ loading: false });
        setTimeout(() => {
          this.props.history.push("/fees-summary");
          // this.setState({ loading: false, visible: false });
        }, 1000);
      } else if (datasuccess.value.status === 401) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        this.setState({ loading: false });
        message.error(datasuccess.value.msg);
      }
    } else {
      this.setState({ loading: false });
      message.error("Please insert all Fields");
    }
  };

  async handleDelete(label, i, i2, filepath) {
    const documents = [...this.state.documents];
    if (i2 === 0) {
      documents[i].files[i2].value = ""
    } else {
      documents[i].files.splice(i2, 1);
    }
    const datasuccess = await this.props.documentRemove({ file_path: "uploads/" + filepath });
    // console.log({ datasuccess });
    this.setState({ documents });
  };

  render() {
    const { service_docbyId, front, feeDatareq, documents } = this.state;
    // console.log(service_docbyId, "ppppppp");
    const token = window.localStorage.getItem('token')
    const uploadButton = <div>{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}</div>;
    const { imageUrl } = this.state;
    var filesLength;
    var extension;
    return (
      <div className="uploadMainSec">

        <div className="addReqHeading">

          {/* <h2> */}
          {/* <Link to="/collection-type"> */}
          {/* <img src="./images/icons/left.png" alt="left" /> */}
          {/* </Link>{" "} */}
          {/* </h2> */}
        </div>
        <Spin spinning={this.state.loading} delay={500}>
          <Form>
            <div className="addRequestInner">
              <div className="addReqContainer">
                <div className="tooltipHeading">
                  <h5 className="heading_5">Upload here the copy documents</h5>
                  <Tooltip placement="bottom" title="Tooltip">
                    <img src="./images/icons/info.png" />
                  </Tooltip>
                </div>
                <div className="DocumentUploadCollapse">
                  {documents.length == 0 ? <p>No Document Required</p> : ""}
                  <Collapse accordion>
                    {documents.map((item, i) => {
                      return (
                        <Panel key={i} header={item.doc_type}>
                          {item.files.map((it, i2) => {
                            {/* console.log(it); */ }
                            filesLength = i2;
                            {/* (i2.length>0) ? extension = it.value.substring(it.value.lastIndexOf('.') + 1): ''; */ }
                            return (
                              <div key={i2} className="mduUpload">
                                <div className="closeAfterupload">
                                  {it.value !== "" ? <Button type="button" onClick={() => this.handleDelete(item.label, i, i2, it.value)}>
                                    <img src="./images/icons/close.png" />
                                  </Button> : ''}
                                </div>
                                <div>
                                <Upload accept="application/pdf, image/*"
                                  name="file"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  headers={{ token: `${token}`}}
                                  action="https://dbcapi.webvilleedemo.xyz/web/document_upload"
                                  // onChange={this.handleChange}
                                  onChange={(file) => this.onChangeHandler(file, i, i2)}
                                >
                                  {it.value !== "" ? (
                                      (it.value.substring(it.value.lastIndexOf('.') + 1) === "pdf") ? <a href={`${Hostname}/${it.value}`} target="_blank">Pdf File</a> : <img src={`${Hostname}/${it.value}`} alt="avatar" style={{ width: "100%" }} />
                                    ) : (
                                        uploadButton
                                      )}
                                </Upload>
                                </div>
                                {it.pic_type !== "" ? (
                                  <p>{it.pic_type}</p>
                                ) : <p style={{color: '#fff'}}>other</p>}
                              </div>
                            );
                          })}
                          {(filesLength < 4) ? <div className="moreUploadbutton">
                            <Button type="primary" onClick={() => this.showModal(item.label, i)}>
                              <PlusOutlined /> Upload More
                          </Button>
                          </div> : ''}
                          {/* <div className="uploadConfirm">
                          <Button type="button">Confirm</Button>
                        </div> */}
                        </Panel>
                      );
                    })}
                  </Collapse>
                </div>

                <div className="tooltipHeading">
                  <h5 className="heading_5">Please verify the availability of below original documents </h5>
                  <Tooltip placement="bottom" title="You can take screenshot of the below for future reference">
                    <img src="./images/icons/info.png" />
                  </Tooltip>
                </div>
                {/* <p>You can take screenshot of the below documents for the future reference</p> */}
                <div className="reqDocList">

                  {feeDatareq.map((items, j) => {
                    return (
                      <div className="docList" key={items._id}><h6>{j + 1}. {items.label}</h6> Note: {items.doc_type}</div>
                    )
                  })}
                </div>

                <div className="impNote">
                  <h3><img src="./images/icons/speaker.png" alt="left" /> <span>Important Note</span></h3>
                  <p>Please acknowledge that the above listed documents should be provided during the scheduled document collection time.</p>
                </div>
                <div className="custom-control custom-checkbox reqDocCheckbox">
                  <input type="checkbox" name="agreeCheck" checked={this.state.agreeCheck} onChange={this.handleCheck} className="custom-control-input" id="customCheck" />
                  <label className="custom-control-label" for="customCheck">I confirm that above listed documents will be provided to the collection agent.</label>
                </div>


              </div>
            </div>
            <div className="addReqSubmit">
              {/* <Link to="/fees-summary" className="btn btn-primary">
            Next
          </Link> */}
              <Input type="submit" onClick={this.handleSubmit} className="btn btn-primary" value="Next" />
            </div>
          </Form>
        </Spin>
        <Modal
          className="testClass"
          title="Upload More"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <form>
            <Spin spinning={this.state.loading} delay={500}><div className="form-group">
              <p className="formLabel">Document Name</p>
              <Input required type="text" name="doc_name" value={(this.state.doc_name) ? this.state.doc_name : ''} onChange={this.handleDoc} className="form-control" />
              {(!this.state.doc_name) ?
                    <span className="invalid-feedback">
                      Please fill this first
                    </span>
                    : ''
              }
            </div>
              <div className="mduUpload">
                <Upload accept="application/pdf, image/*"
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  headers={{ token: `${token}`}}
                  action="https://dbcapi.webvilleedemo.xyz/web/document_upload"
                  onChange={file => this.onChangeModal(file)}
                  disabled={(this.state.doc_name)? false: true}
                >
                  {this.state.pic_url ? (
                    <img src={`${Hostname}/${this.state.pic_url}`} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                      uploadButton
                    )}
                </Upload>
                <p>Upload</p>
                {/* <Input type="submit" value="Submit"></Input> */}
              </div></Spin>
          </form>
        </Modal>
      </div>
    );
  }
}
