import React from "react";
// import {BrowserRouter as Link } from "react-router-dom";
// import { Formik, ErrorMessage } from "formik";
import "./styles.scss";

const StepFirst = () => {

  return (
    <div>
      <div className="appStartingScreen">
      <div className="appStartingScreenInner">
        <div className="ass-content">
          <div className="ass-image">
            <img src="./images/starting-1.png"  alt="Starting"/>
          </div>
          <h1 className="heading">No More Waiting queues</h1>
          <p>Get rid of standing in long <br/> waiting queues</p>
        </div>
        <div className="ass-buttons">
        <a href="/StepSecond" className="ass-btn ass-btn-primary">Next</a>
        <a href="/signin"><button className="ass-btn ass-btn-secondary">Skip</button></a>
        </div>
      </div>
      </div>
    </div>
  );
};

export default StepFirst;
