import { connect } from 'react-redux'
import ProfileComponent from './component'
import {  updateUserDetails, getUserDetails} from '../../store/user/duck'

const ProfileContainer = connect(
  // Map state to props
  state => ({
    message: state.user.message,
    user: state.user.user,
    loginPhase: state.user.loginPhase
  }),
  {
    updateUserDetails,
    getUserDetails
  }
  // Map actions to dispatch and props
)(ProfileComponent)

export default ProfileContainer
