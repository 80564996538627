import { connect } from 'react-redux'
import TransactionsComponent from './component'
import { getTransactionByfilter } from '../../store/request/duck'
import { createPdf } from '../../store/request/duck'
const TransactionsContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getTransactionByfilter,
    createPdf
  }
  // Map actions to dispatch and props
)(TransactionsComponent)

export default TransactionsContainer
